//AWS config
const config = {
	apiGateway: {
		REGION: "ap-southeast-2",
		URL: "https://v1qk5m3l9h.execute-api.ap-southeast-2.amazonaws.com/dev",
	},
	cognito: {
		REGION: "ap-southeast-2",
		USER_POOL_ID: "ap-southeast-2_uFIQa7QEl",
		//USER_POOL_ID: "ap-southeast-2_mL7Lfcxcm",
		APP_CLIENT_ID: "6qbpchra2iebtfhbb9obi4hfss",
	},
};

export default config;
