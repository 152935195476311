import React from "react";
import "./dashboard.css";
import Paper from "@material-ui/core/Paper";
import Chart from "react-google-charts";
import MapContainer from "./map.js";
import { CircularProgress } from "@material-ui/core";

//Written by Harrison Norris
export default function Dashboard(props) {
	const [regions, setRegions] = React.useState([]);
	const [monthTotals, setMonthTotals] = React.useState([]);
	const [counts, setCounts] = React.useState([]);
	const [inactive, setInactive] = React.useState(null);
	const [total, setTotal] = React.useState(null);

	React.useEffect(() => {
		getGroupData();
	}, [props.groups]);
	React.useEffect(() => {
		getRegionData();
		getInactiveData();
		getTotalData();
		getMonthTotalData();
	}, [props.providers]);

	//Creates array for group count pie graph
	const getGroupData = () => {
		var data = [];
		data.push(["Group", "Count", { role: "style" }]);
		for (var i in props.groups) {
			data.push([props.groups[i].name, props.groups[i].count, getNextColor()]);
		}
		setCounts(data);
	};

	var nextColor = 0;
	var colors = [
		"#FBDEDE",
		"#FEBD7D",
		"#8ABADE",
		"#BBDCAD",
		"#F59598",
		"#B3B4DA",
		"#A9DAD1",
		"#F9F08D",
		"#EFA796",
		"#A6C7EA",
		"#88BBAA",
		"#F1ED8C",
	];

	//Returns colors from array in order
	const getNextColor = () => {
		nextColor++;
		if (nextColor > 11) {
			nextColor = 0;
		}
		return colors[nextColor];
	};

	//Calculates number of inactive providers
	const getInactiveData = () => {
		var inactive = 0;
		for (var i in props.providers) {
			if (!props.providers[i].Active) {
				inactive++;
			}
		}
		setInactive(inactive);
	};

	//Returns this year for months passed or current and last year for months yet to come
	const getYear = (month) => {
		var date = new Date();
		var fullYear = date.getFullYear() + "";
		var fullLastYear = date.getFullYear() - 1 + "";
		var year = fullYear.slice(2);
		var lastYear = fullLastYear.slice(2);
		if (month <= date.getMonth()) {
			return year;
		} else {
			return lastYear;
		}
	};

	//Gets total number of providers
	const getTotalData = () => {
		setTotal(props.providers.length);
	};

	//Calculates month totals
	const getMonthTotalData = () => {
		getYear(1);
		var months = [];

		months.push(["January '" + getYear(0), 0, getNextColor()]);
		months.push(["February '" + getYear(1), 0, getNextColor()]);
		months.push(["March '" + getYear(2), 0, getNextColor()]);
		months.push(["April '" + getYear(3), 0, getNextColor()]);
		months.push(["May '" + getYear(4), 0, getNextColor()]);
		months.push(["June '" + getYear(5), 0, getNextColor()]);
		months.push(["July '" + getYear(6), 0, getNextColor()]);
		months.push(["August '" + getYear(7), 0, getNextColor()]);
		months.push(["September '" + getYear(8), 0, getNextColor()]);
		months.push(["October '" + getYear(9), 0, getNextColor()]);
		months.push(["November '" + getYear(10), 0, getNextColor()]);
		months.push(["December '" + getYear(11), 0, getNextColor()]);

		for (var i = 0; i < props.providers.length; i++) {
			var date = props.providers[i].CreationDate;
			if (date.search("/01/" + getYear(0)) !== -1) {
				months[0][1]++;
			}
			if (date.search("/02/" + getYear(1)) !== -1) {
				months[1][1]++;
			}
			if (date.search("/03/" + getYear(2)) !== -1) {
				months[2][1]++;
			}
			if (date.search("/04/" + getYear(3)) !== -1) {
				months[3][1]++;
			}
			if (date.search("/05/" + getYear(4)) !== -1) {
				months[4][1]++;
			}
			if (date.search("/06/" + getYear(5)) !== -1) {
				months[5][1]++;
			}
			if (date.search("/07/" + getYear(6)) !== -1) {
				months[6][1]++;
			}
			if (date.search("/08/" + getYear(7)) !== -1) {
				months[7][1]++;
			}
			if (date.search("/09/" + getYear(8)) !== -1) {
				months[8][1]++;
			}
			if (date.search("/10/" + getYear(9)) !== -1) {
				months[9][1]++;
			}
			if (date.search("/11/" + getYear(10)) !== -1) {
				months[10][1]++;
			}
			if (date.search("/12/" + getYear(11)) !== -1) {
				months[11][1]++;
			}
		}
		var dateObj = new Date();
		for (let i = 0; i < dateObj.getMonth() + 1; i++) {
			var temp = months[0];
			months.splice(0, 1);
			months.push(temp);
		}
		months.unshift(["Month ", "Count", { role: "style" }]);
		setMonthTotals(months);
	};

	//Calculates region based on postcode
	const getRegionData = () => {
		var regions = [];

		regions.push(["Central & Northern Sydney", 0, getNextColor()]); //0
		regions.push(["Southern & South Western Sydney", 0, getNextColor()]); //1
		regions.push(["Western Sydney & Blue Mountains", 0, getNextColor()]); //2
		regions.push(["Hunter & Central Coast", 0, getNextColor()]); //3
		regions.push(["North Coast & Mid North Coast", 0, getNextColor()]); //4
		regions.push(["New England", 0, getNextColor()]); //5
		regions.push(["Western NSW", 0, getNextColor()]); //6
		regions.push(["Illawarra & South Eastern NSW (Other)", 0, getNextColor()]); //7
		regions.push(["Riverina", 0, getNextColor()]); //8
		regions.push(["ACT", 0, getNextColor()]); //9
		regions.push(["VIC", 0, getNextColor()]); //10
		regions.push(["QLD", 0, getNextColor()]); //11
		regions.push(["SA", 0, getNextColor()]); //12
		regions.push(["NT", 0, getNextColor()]); //13
		regions.push(["WA", 0, getNextColor()]); //14
		regions.push(["TAS", 0, getNextColor()]); //15
		regions.push(["Central Wollongong", 0, getNextColor()]); //16
		regions.push(["Wollongong Northern Suburbs", 0, getNextColor()]); //17
		regions.push(["Wollongong Western Suburbs", 0, getNextColor()]); //18
		regions.push(["Wollongong Southern Suburbs", 0, getNextColor()]); //19
		regions.push(["Wollongong Southwest Suburbs", 0, getNextColor()]); //20
		regions.push(["Shellharbour and Suburbs", 0, getNextColor()]); //21
		regions.push(["Kiama-Berry Area", 0, getNextColor()]); //22
		regions.push(["Nowra and Suburbs", 0, getNextColor()]); //23

		for (var i = 0; i < props.providers.length; i++) {
			var pc = parseInt(props.providers[i].PostCode);

			if (pc > 2599 && pc < 2619) {
				//ACT
				regions[9][1]++;
			}
			if (pc > 2890 && pc < 2921) {
				//ACT
				regions[9][1]++;
			}
			if (pc > 2999 && pc < 4000) {
				//VIC
				regions[10][1]++;
			}
			if (pc > 3999 && pc < 5000) {
				//QLD
				regions[11][1]++;
			}
			if (pc > 4999 && pc < 6000) {
				//SA
				regions[12][1]++;
			}
			if (pc > 5999 && pc < 7000) {
				//WA
				regions[14][1]++;
			}
			if (pc > 6999 && pc < 8000) {
				//TAS
				regions[15][1]++;
			}
			if (pc > 799 && pc < 1000) {
				//NT
				regions[13][1]++;
			}
			if (pc > 1999 && pc < 2020) {
				//C N S
				regions[0][1]++;
			}
			if (pc === 2020) {
				//S SW S
				regions[1][1]++;
			}
			if (pc > 2020 && pc < 2038) {
				//C N S
				regions[0][1]++;
			}
			if (pc > 2037 && pc < 2042) {
				//WS BM
				regions[2][1]++;
			}
			if (pc > 2041 && pc < 2045) {
				//C N S
				regions[0][1]++;
			}
			if (pc > 2044 && pc < 2048) {
				//WS BM
				regions[2][1]++;
			}
			if (pc === 2048) {
				//C N S
				regions[0][1]++;
			}
			if (pc === 2049) {
				//S SW S
				regions[1][1]++;
			}
			if (pc > 2049 && pc < 2115) {
				//C N S
				regions[0][1]++;
			}
			if (pc > 2114 && pc < 2019) {
				//WS BM
				regions[2][1]++;
			}
			if (pc > 2018 && pc < 2023) {
				//C N S
				regions[0][1]++;
			}
			if (pc > 2122 && pc < 2156) {
				//WS BM
				regions[2][1]++;
			}
			if (pc === 2126) {
				//C N S
				regions[0][1]++;
			}
			if (pc > 2126 && pc < 2129) {
				//WS BM
				regions[2][1]++;
			}
			if (pc > 2129 && pc < 2037) {
				//S SW S
				regions[1][1]++;
			}
			if (pc > 2136 && pc < 2141) {
				//WS BM
				regions[2][1]++;
			}
			if (pc === 2141) {
				//S SW S
				regions[1][1]++;
			}
			if (pc === 2142) {
				//WS BM
				regions[2][1]++;
			}
			if (pc === 2143) {
				//S SW S
				regions[1][1]++;
			}
			if (pc > 2143 && pc < 2159) {
				//WS BM
				regions[2][1]++;
			}
			if (pc === 2159) {
				//C N S
				regions[0][1]++;
			}
			if (pc === 2160) {
				//WS BM
				regions[2][1]++;
			}
			if (pc > 2160 && pc < 2204) {
				//S SW S
				regions[1][1]++;
			}
			if (pc === 2204) {
				//C N S
				regions[0][1]++;
			}
			if (pc > 2204 && pc < 2235) {
				//S SW S
				regions[1][1]++;
			}
			if (pc > 2249 && pc < 2312) {
				//H CC
				regions[3][1]++;
			}
			if (pc === 2312) {
				//NC MNC
				regions[4][1]++;
			}
			if (pc > 2312 && pc < 2340) {
				//H CC
				regions[4][1]++;
			}
			if (pc > 2339 && pc < 2386) {
				//NE
				regions[5][1]++;
			}
			if (pc > 2385 && pc < 2388) {
				//W
				regions[6][1]++;
			}
			if (pc > 2387 && pc < 2412) {
				//NE
				regions[5][1]++;
			}
			if (pc > 2414 && pc < 2426) {
				//H CC
				regions[3][1]++;
			}
			if (pc > 2425 && pc < 2491) {
				//NC MNC
				regions[4][1]++;
			}
			if (pc > 2499 && pc < 2552) {
				if (pc === 2500) {
					//Central Wollongong
					regions[16][1]++;
				} else if (pc > 2507 && pc < 2520) {
					//Wollongong Northern Suburbs
					regions[17][1]++;
				} else if (pc > 2524 && pc < 2527) {
					//Wollongong Western Suburbs
					regions[18][1]++;
				} else if (pc > 2501 && pc < 2507) {
					//Wolongong Southern Suburbs
					regions[19][1]++;
				} else if (pc === 2530) {
					//Dapto & Suburbs
					regions[20][1]++;
				} else if (pc > 2526 && pc < 2530) {
					//Shellharbour & Suburbs
					regions[21][1]++;
				} else if (pc > 2532 && pc < 2536) {
					//Kiama-Berry
					regions[22][1]++;
				} else if (pc > 2539 && pc < 2542) {
					//Nowra
					regions[23][1]++;
				} else {
					//I SE
					regions[7][1]++;
				}
			}
			if (pc > 2584 && pc < 2595) {
				//RIV
				regions[8][1]++;
			}
			if (pc > 2599 && pc < 2634) {
				//I SE
				regions[7][1]++;
			}
			if (pc > 2639 && pc < 2670) {
				//RIV
				regions[8][1]++;
			}
			if (pc > 2670 && pc < 2673) {
				//W
				regions[6][1]++;
			}
			if (pc > 2674 && pc < 2721) {
				//RIV
				regions[8][1]++;
			}
			if (pc === 2721) {
				//W
				regions[6][1]++;
			}
			if (pc > 2721 && pc < 2739) {
				//RIV
				regions[8][1]++;
			}
			if (pc > 2744 && pc < 2752) {
				//WS BM
				regions[2][1]++;
			}
			if (pc === 2752) {
				//S SW S
				regions[1][1]++;
			}
			if (pc > 2752 && pc < 2787) {
				//WS BM
				regions[2][1]++;
			}
			if (pc > 2786 && pc < 2801) {
				//W
				regions[6][1]++;
			}
			if (pc === 2803) {
				//RIV
				regions[8][1]++;
			}
			if (pc > 2803 && pc < 2807) {
				//W
				regions[6][1]++;
			}
			if (pc === 2807) {
				//RIV
				regions[8][1]++;
			}
			if (pc > 2807 && pc < 2881) {
				//W
				regions[6][1]++;
			}
			if (pc > 2897 && pc < 2900) {
				//NC MNC
				regions[4][1]++;
			}
			if (pc > 2899 && pc < 2915) {
				//I SE
				regions[7][1]++;
			}
		}
		var cutRegions = [];
		for (var j = regions.length; j >= 0; j--) {
			if (regions[j] !== undefined) {
				if (!regions[j][1] < 1) {
					cutRegions.push(regions[j]);
				}
			}
		}
		cutRegions.unshift(["Region", "Count", { role: "style" }]);
		setRegions(cutRegions);
	};

	return (
		<div id="background">
			<div id="dashboardContainer">
				<div id="grid">
					<Paper id="card1">
						<h4>Group Counts</h4>
						<div id="chart">
							{counts.length < 2 ? (
								<CircularProgress />
							) : (
								<Chart
									width="100%"
									height="100%"
									chartType="ColumnChart"
									data={counts}
									options={{ legend: "none" }}
								/>
							)}
						</div>
					</Paper>
					<Paper id="card6">
						<MapContainer providers={props.providers}></MapContainer>
					</Paper>
					<Paper id="card2">
						<h4>Provider Locations</h4>
						<div id="chart">
							{regions.length < 2 ? (
								<CircularProgress />
							) : (
								<Chart
									width="100%"
									height="100%"
									chartType="PieChart"
									data={regions}
									options={{ colors: colors }}
								/>
							)}
						</div>
					</Paper>
					<Paper id="card3">
						<h4 style={{ fontSize: 20 }}>Inactive Records</h4>
						{inactive == null ? (
							<CircularProgress />
						) : (
							<h1 style={{ fontSize: 80 }}>{inactive}</h1>
						)}
					</Paper>
					<Paper id="card4">
						<h4 style={{ fontSize: 20 }}>Total Records</h4>
						{total == null ? (
							<CircularProgress />
						) : (
							<h1 style={{ fontSize: 80 }}>{total}</h1>
						)}
					</Paper>
					<Paper id="card5">
						<h4>New Records Per Month</h4>
						<div id="chart">
							{monthTotals.length < 2 ? (
								<CircularProgress />
							) : (
								<Chart
									width="100%"
									height="100%"
									chartType="ColumnChart"
									data={monthTotals}
									options={{ legend: "none" }}
								/>
							)}
						</div>
					</Paper>
				</div>
			</div>
		</div>
	);
}
