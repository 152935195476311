import React from "react";
import {
	Switch,
	FormControl,
	FormGroup,
	ThemeProvider,
	TextField,
	Snackbar,
	withStyles,
	FormControlLabel,
	Box,
	Button,
	IconButton,
	ButtonGroup,
} from "@material-ui/core";
import { Export } from "./Dialogs/exportRecords";
import { ImportRecords } from "./Dialogs/importRecords";
import { Email } from "./Dialogs/email";
import { Fax } from "./Dialogs/fax";
import { AddRecords } from "./Dialogs/addRecords";
import { AddGroup } from "./Dialogs/addGroup";
import { ExpandLess, ExpandMore, Add, Close } from "@material-ui/icons";
import Expand from "react-expand-animated";
import Auth from "@aws-amplify/auth";
import styles from "../Utilities/styles";

//Written by Harrison Norris
export default function Toolbar(props) {
	const [expandedSearch, setExpandedSearch] = React.useState(false);
	const [expandedGroups, setExpandedGroups] = React.useState(false);
	const [addGroupOpen, setAddGroupOpen] = React.useState(false);
	const [emailOpen, setEmailOpen] = React.useState(false);
	const [faxOpen, setFaxOpen] = React.useState(false);
	const [addRecordsOpen, setAddRecordsOpen] = React.useState(false);
	const [importOpen, setImportOpen] = React.useState(false);
	const [exportOpen, setExportOpen] = React.useState(false);
	const [noSelectSBOpen, setNoSelectSBOpen] = React.useState(false);
	const [selectedProviders, setSelectedProviders] = React.useState([]);
	const [userIsAdmin, setUserIsAdmin] = React.useState(false);
	const [userIsReadWrite, setUserIsReadWrite] = React.useState(false);

	//check permissions
	React.useEffect(() => {
		Auth.currentAuthenticatedUser().then((user) => {
			if (
				user.signInUserSession.idToken.payload["cognito:groups"] !==
					undefined &&
				user.signInUserSession.idToken.payload["cognito:groups"][0] ===
					"read/write"
			) {
				setUserIsReadWrite(true);
			} else {
				setUserIsReadWrite(false);
			}
			if (
				user.signInUserSession.idToken.payload["cognito:groups"] !==
					undefined &&
				user.signInUserSession.idToken.payload["cognito:groups"][0] === "admin"
			) {
				setUserIsAdmin(true);
				setUserIsReadWrite(true);
			} else {
				setUserIsAdmin(false);
			}
		});
	}, []);
	//Checks for selected records and adds them to an array
	const selectedExists = () => {
		var selected = [];
		for (var i in props.providers) {
			if (props.providers[i].selected) {
				selected.push(props.providers[i]);
			}
		}
		setSelectedProviders(selected);
		if (selected.length < 1) {
			setNoSelectSBOpen(true);
			return false;
		} else {
			return true;
		}
	};

	return (
		<Box id="toolbar" boxShadow="3">
			<p>Search</p>
			<ThemeProvider theme={styles.themeToolbar}>
				<TextField
					id="fName"
					variant="standard"
					label="First Name"
					autoComplete="new-password" //stops autofill from running
					onKeyUp={() => props.searchProviders()}
					aria-label="First Name Search"
				></TextField>
				<TextField
					id="lName"
					variant="standard"
					label="Last Name"
					autoComplete="new-password"
					onKeyUp={() => props.searchProviders()}
					aria-label="Last Name Search"
				></TextField>
				<TextField
					id="providerNum"
					variant="standard"
					label="Provider Number"
					autoComplete="new-password"
					onKeyUp={() => props.searchProviders()}
					aria-label="Provider Number Search"
				></TextField>
				<TextField
					id="practice"
					variant="standard"
					label="Practice"
					autoComplete="new-password"
					onKeyUp={() => props.searchProviders()}
					aria-label="Practice Search"
				></TextField>
				<Expand open={expandedSearch}>
					<form noValidate>
						<TextField
							id="position"
							variant="standard"
							label="Position"
							autoComplete="new-password"
							onKeyUp={() => props.searchProviders()}
							aria-label="Position Search"
						></TextField>
						<TextField
							id="address"
							variant="standard"
							label="Address"
							autoComplete="new-password"
							onKeyUp={() => props.searchProviders()}
							aria-label="Address Search"
						></TextField>
						<TextField
							id="suburb"
							variant="standard"
							label="Suburb"
							autoComplete="new-password"
							onKeyUp={() => props.searchProviders()}
							aria-label="Suburb Search"
						></TextField>
						<TextField
							id="state"
							variant="standard"
							label="State"
							autoComplete="new-password"
							onKeyUp={() => props.searchProviders()}
							aria-label="State Search"
						></TextField>
						<TextField
							id="postcode"
							variant="standard"
							label="Postcode"
							autoComplete="new-password"
							onKeyUp={() => props.searchProviders()}
							aria-label="Post Code Search"
						></TextField>
						<TextField
							id="phone"
							variant="standard"
							label="Phone"
							autoComplete="new-password"
							onKeyUp={() => props.searchProviders()}
							aria-label="Phone Number Search"
						></TextField>
						<TextField
							id="fax"
							variant="standard"
							label="Fax"
							autoComplete="new-password"
							onKeyUp={() => props.searchProviders()}
							aria-label="Fax Number Search"
						></TextField>
						<TextField
							id="email"
							variant="standard"
							label="Email"
							autoComplete="new-password"
							onKeyUp={() => props.searchProviders()}
							aria-label="Email Address Search"
						></TextField>
					</form>
				</Expand>
				<Button
					variant="text"
					endIcon={expandedSearch ? <ExpandLess /> : <ExpandMore />}
					onClick={() => setExpandedSearch(!expandedSearch)}
					aria-label="Expand Search Groups"
				>
					{expandedSearch ? "Less" : "More"}
				</Button>
			</ThemeProvider>
			<ThemeProvider theme={styles.themeDefault}>
				<p>Filter</p>
				<FormControl component="fieldset">
					<FormGroup component="legend">
						{props.groups.map((value, i) => {
							if (i < 3) {
								return (
									<FilterSwitch
										key={i}
										control={
											<Switch
												checked={value.active}
												onChange={() => props.toggleFilter(i)}
												aria-label="Filter Switch"
											></Switch>
										}
										label={value.name}
										labelPlacement="start"
									/>
								);
							} else {
								if (expandedGroups) {
									return (
										<FilterSwitch
											key={i}
											control={
												<Switch
													checked={value.active}
													onChange={() => props.toggleFilter(i)}
													aria-label="Filter Switch"
												></Switch>
											}
											label={value.name}
											labelPlacement="start"
										/>
									);
								} else {
									return null;
								}
							}
						})}
						<ThemeProvider theme={styles.themeToolbar}>
							<ButtonGroup orientation="vertical">
								<Button
									variant="text"
									endIcon={expandedGroups ? <ExpandLess /> : <ExpandMore />}
									onClick={() => setExpandedGroups(!expandedGroups)}
									aria-label="Expand Groups"
								>
									{expandedGroups ? "Less" : "More"}
								</Button>
								<Button
									disabled={!userIsAdmin}
									variant="text"
									endIcon={<Add></Add>}
									onClick={() => setAddGroupOpen(true)}
									aria-label="Add Group"
								>
									Add
								</Button>
							</ButtonGroup>
							<AddGroup
								open={addGroupOpen}
								onClose={() => setAddGroupOpen(false)}
								groups={props.groups}
								setGroups={props.setGroups}
								logout={props.logout}
							></AddGroup>
						</ThemeProvider>
					</FormGroup>
				</FormControl>
			</ThemeProvider>
			<ThemeProvider theme={styles.themeToolbar}>
				<p>Action</p>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (selectedExists()) {
							setEmailOpen(true);
						}
					}}
					style={styles.toolbarButton}
				>
					Email Selection
				</Button>
				<Email
					open={emailOpen}
					selected={selectedProviders}
					onClose={() => setEmailOpen(false)}
					providers={props.providers}
				></Email>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (selectedExists()) {
							setFaxOpen(true);
						}
					}}
					style={styles.toolbarButton}
				>
					Fax Selection
				</Button>

				<Fax
					open={faxOpen}
					selected={selectedProviders}
					onClose={() => setFaxOpen(false)}
					providers={props.providers}
				></Fax>
				<p>Manage</p>
				<Button
					disabled={!userIsReadWrite}
					variant="outlined"
					color="primary"
					onClick={() => setAddRecordsOpen(true)}
					style={styles.toolbarButton}
				>
					Add Records
				</Button>
				<AddRecords
					open={addRecordsOpen}
					onClose={() => setAddRecordsOpen(false)}
					logout={props.logout}
					groups={props.groups}
					providers={props.providers}
					updateProvider={props.updateProvider}
					setProviders={props.setProviders}
					setProvider={(provider, stayOpen) =>
						props.setProvider(provider, stayOpen)
					}
				></AddRecords>
				<Button
					disabled={!userIsReadWrite}
					variant="outlined"
					color="primary"
					onClick={() => setImportOpen(true)}
					style={styles.toolbarButton}
				>
					Import From .CSV
				</Button>
				<ImportRecords
					readProviders={props.readProviders}
					open={importOpen}
					onClose={() => setImportOpen(false)}
					createProvider={props.createProvider}
					groups={props.groups}
					providers={props.providers}
					updateProvider={props.updateProvider}
					setProviders={props.setProviders}
					logout={props.logout}
				></ImportRecords>
				<Button
					variant="outlined"
					color="primary"
					onClick={() => {
						if (selectedExists()) {
							setExportOpen(true);
						}
					}}
					style={styles.toolbarButton}
				>
					Export Records
				</Button>
				<Export
					open={exportOpen}
					selected={selectedProviders}
					onClose={() => setExportOpen(false)}
					providers={props.providers}
				></Export>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={noSelectSBOpen}
					autoHideDuration={2000}
					onClose={() => setNoSelectSBOpen(false)}
					message="No Records Selected"
					aria-label="No Records Selected"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setNoSelectSBOpen(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
			</ThemeProvider>
		</Box>
	);
}

const FilterSwitch = withStyles((theme) => ({
	root: {
		color: "white",
	},
}))(FormControlLabel);
