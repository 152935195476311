export default function FaxFormatter(faxNumber) {
	var fax = faxNumber.replace(/\s+/g, "");

	if (fax.length !== 0) {
		if (fax[0] === "+") {
			return fax.substring(1);
		} else if (fax[0] === "0") {
			return "61" + fax.substring(1);
		} else if (fax[0] === "6" && fax[1] === "1") {
			return fax;
		} else if (fax.length === 8) {
			return "61" + fax;
		}
	} else {
		return "";
	}
}
