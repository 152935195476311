import React from "react";
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	DialogActions,
	ThemeProvider,
	Snackbar,
	IconButton,
} from "@material-ui/core";
import styles from "../../Utilities/styles";
import { Close } from "@material-ui/icons";
import { ExportToCsv } from "export-to-csv";

//Written by Harrison Norris
export const Export = React.memo(
	(props) => {
		const [recordsExported, setRecordsExported] = React.useState(false);

		//exports records to .csv format
		const exportRecords = () => {
			var date = new Date();
			var name =
				"Export: " +
				date.getDate() +
				"-" +
				(date.getMonth() + 1) +
				"-" +
				date.getFullYear();
			const options = {
				fieldSeparator: ",",
				quoteStrings: '"',
				decimalSeparator: ".",
				filename: name,
				showLabels: true,
				showTitle: false,
				useTextFile: false,
				useBom: true,
				useKeysAsHeaders: true,
			};
			const csvExporter = new ExportToCsv(options);

			var exportData = [];

			for (var i in props.selected) {
				var unsorted = props.selected[i];
				var sorted = {
					ProviderNumber: unsorted.ProviderNumber,
					Title: unsorted.Title,
					FirstName: unsorted.FirstName,
					LastName: unsorted.LastName,
					Practice: unsorted.Practice,
					Position: unsorted.Position,
					Street: unsorted.Street,
					Suburb: unsorted.Suburb,
					State: unsorted.State,
					PostCode: unsorted.PostCode,
					Email: unsorted.Email,
					Phone: unsorted.Phone,
					Fax: unsorted.Fax,
					Referrals: unsorted.Referrals,
				};
				exportData.push(sorted);
			}
			csvExporter.generateCsv(exportData);
			setRecordsExported(true);
			props.onClose();
		};

		return (
			<ThemeProvider theme={styles.themeDefault}>
				<Dialog open={props.open} onClose={() => props.onClose()}>
					<DialogTitle>Export Records</DialogTitle>
					<DialogContent>
						{"Are you sure you want to export " +
							props.selected.length +
							" selected records to .CSV?"}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => props.onClose()}>Cancel</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => exportRecords()}
						>
							Export
						</Button>
					</DialogActions>
				</Dialog>

				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={recordsExported}
					autoHideDuration={2000}
					onClose={() => setRecordsExported(false)}
					message="Record(s) Exported"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setRecordsExported(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
			</ThemeProvider>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
