import React from "react";
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	DialogActions,
	ThemeProvider,
	OutlinedInput,
	Snackbar,
	IconButton,
} from "@material-ui/core";
import styles from "../../Utilities/styles";
import { Close } from "@material-ui/icons";

//Written by Harrison Norris
export const Email = React.memo(
	(props) => {
		const [confirmOpen, setConfirmOpen] = React.useState(false);
		const [emails, setEmails] = React.useState(null);

		//get email address from selected providers
		React.useEffect(() => {
			var emails = "";
			for (var i in props.selected) {
				if (
					!emails.includes(props.selected[i].Email) &&
					props.selected[i].Email !== null &&
					props.selected[i].Email !== undefined
				) {
					emails = emails + props.selected[i].Email + ", ";
				}
			}
			setEmails(emails);
		}, [props.selected]);

		//Copy emails to clipboard
		const copy = (emails) => {
			navigator.clipboard.writeText(emails);
			props.onClose();
			setConfirmOpen(true);
		};
		return (
			<>
				<ThemeProvider theme={styles.themeDefault}>
					<Dialog open={props.open} onClose={() => props.onClose()}>
						<DialogTitle>Email Selection</DialogTitle>
						<DialogContent>
							<div style={{ width: 500 }}>
								<OutlinedInput
									multiline
									rows={6}
									defaultValue={emails}
									variant="outlined"
									fullWidth
									readOnly
								/>
							</div>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => props.onClose()}>Cancel</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() => copy(emails)}
							>
								Copy to Clipboard
							</Button>
						</DialogActions>
					</Dialog>
				</ThemeProvider>

				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={confirmOpen}
					autoHideDuration={2000}
					onClose={() => setConfirmOpen(false)}
					message="Record(s) copied to clipboard"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setConfirmOpen(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
			</>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
