import { createMuiTheme } from "@material-ui/core";

//holds style data for use in various components
const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#455A64",
		},
		secondary: {
			main: "#E57373",
		},
	},
});
const themeToolbar = createMuiTheme({
	palette: {
		type: "dark",
		primary: {
			main: "#FFFFFF",
		},
		secondary: {
			main: "#E57373",
		},
	},
});
const themeTabBar = createMuiTheme({
	palette: {
		primary: {
			main: "#FFFFFF",
		},
		secondary: {
			main: "#455A64",
		},
	},
});
const toolbarButton = {
	width: 190,
	marginBottom: 16,
};
const styles = {
	toolbarButton: toolbarButton,
	themeDefault: theme,
	themeToolbar: themeToolbar,
	themeTabBar: themeTabBar,
};
export default styles;
