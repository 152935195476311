import React from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import "./dashboard.css";

//css for the map
const mapStyles = {
	borderRadius: 4,
	overflow: "hidden",
};

//Written by Sarah Swyer
export function MapContainer(props) {
	const [showingInfoWindow, setShowingInfo] = React.useState(false);
	const [activeMarker, setActiveMarker] = React.useState({});
	const [selectedPlace, setSelectedPlace] = React.useState({});

	//Set state for marker click
	const onMarkerClick = (props, marker, e) => {
		setSelectedPlace(props);
		setActiveMarker(marker);
		setShowingInfo(true);
	};

	//Set state for info window close
	const onClose = (props) => {
		if (showingInfoWindow) {
			setShowingInfo(false);
			setActiveMarker(null);
		}
	};

	return (
		<Map
			style={mapStyles}
			google={props.google}
			zoom={14}
			initialCenter={{
				lat: -34.427933754813104,
				lng: 150.89301556507363,
			}}
		>
			{props.providers.map((provider, i) => {
				if (provider.Latitude !== "") {
					return [
						<Marker
							onClick={onMarkerClick}
							key={i}
							position={{ lat: provider.Latitude, lng: provider.Longitude }}
							name={provider.FirstName + " " + provider.LastName}
							practice={provider.Practice}
						/>,
						<InfoWindow
							marker={activeMarker}
							visible={showingInfoWindow}
							onClose={() => onClose()}
						>
							<div>
								<h5>{selectedPlace.practice}</h5>
								<h6>{selectedPlace.name}</h6>
							</div>
						</InfoWindow>,
					];
				}
			})}
		</Map>
	);
}

export default GoogleApiWrapper({
	apiKey: "AIzaSyAPLIRKknkw1j5leB9wBigFdVqI2hQVxMs",
})(MapContainer);
