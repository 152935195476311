//Written by Harrison Norris
//Functions to validate various fields
//Functions return true if validation is successful
export default class Validate {
	static username(username) {
		const res = /^([\w\d\_\-\.]*$)/;
		if (res.test(String(username)) || username.length === 0) {
			return true;
		} else {
			return false;
		}
	}
	static email(email) {
		const res =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (res.test(String(email).toLowerCase()) || email.length === 0) {
			return true;
		} else {
			return false;
		}
	}
	static password(password) {
		const res =
			/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
		return res.test(String(password));
	}
	static resetCode(code) {
		const res = /[0-9]/;
		if (code.length !== 6 || !res.test(String(code))) {
			return false;
		} else {
			return true;
		}
	}
	static providerNumber(num) {
		if (num !== undefined) {
			if (num.length > 8) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}
	static suburb(suburb) {
		const res = /^[a-zA-Z\s]*$/;
		if (suburb !== undefined) {
			return res.test(String(suburb));
		} else {
			return true;
		}
	}
	static state(state) {
		if (state !== undefined) {
			if ((state.length < 2 || state.length > 3) && !state.length === 0) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}
	static postcode(postcode) {
		if (postcode !== undefined) {
			if (postcode.length !== 4 && postcode.length !== 0) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}
	static phone(phone) {
		const res = /[0-9]/;
		if (phone !== undefined) {
			if (res.test(String(phone)) || phone.length === 0) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
	static fax(fax) {
		const res = /[0-9]/;
		if (fax !== undefined) {
			if (res.test(String(fax)) || fax.length === 0) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
	static provider(provider) {
		var newError = {
			providerNumber: !Validate.providerNumber(provider.ProviderNumber),
			email: !Validate.email(provider.Email),
			suburb: !Validate.suburb(provider.Suburb),
			state: !Validate.state(provider.State),
			postcode: !Validate.postcode(provider.Postcode),
			phone: !Validate.phone(provider.Phone),
			fax: !Validate.fax(provider.Fax),
		};

		if (
			newError.providerNumber ||
			newError.email ||
			newError.suburb ||
			newError.state ||
			newError.postcode ||
			newError.phone ||
			newError.fax
		) {
			return { pass: false, errors: newError };
		} else if (
			provider.Title.length === 0 &&
			provider.FirstName.length === 0 &&
			provider.LastName.length === 0 &&
			provider.ProviderNumber.length === 0 &&
			provider.Email.length === 0 &&
			provider.Practice.length === 0 &&
			provider.Position.length === 0 &&
			provider.Address.length === 0 &&
			provider.Suburb.length === 0 &&
			provider.State.length === 0 &&
			provider.Postcode.length === 0 &&
			provider.Phone.length === 0 &&
			provider.Fax.length === 0
		) {
			return { pass: false, errors: newError };
		} else {
			return { pass: true, errors: newError };
		}
	}

	static toTitleCase = (str) => {
		return str
			.replace(/\w\S*/g, function (txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			})
			.trim();
	};
}
