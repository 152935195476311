import React from "react";
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	DialogActions,
	ThemeProvider,
	Snackbar,
	IconButton,
	TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import styles from "../../Utilities/styles";
import { API, Auth } from "aws-amplify";
import { NoAuthentication } from "../../notAuthenticated";
import { NoAuthorization } from "../../notAuthorized";

//Written by Harrison Norris
export const AddGroup = React.memo(
	(props) => {
		const [error, setError] = React.useState(false);
		const [duplicate, setDuplicate] = React.useState(false);
		const [snackbar, setSnackbar] = React.useState(false);
		const [noAuthentication, setNoAuthentication] = React.useState(false);
		const [noAuthorization, setNoAuthorization] = React.useState(false);

		//Validates group name
		const validate = () => {
			if (document.getElementById("groupName").value.length > 15) {
				setError(true);
				return;
			} else {
				setError(false);
			}
			setDuplicate(false);
			for (var i in props.groups) {
				if (
					document.getElementById("groupName").value.toUpperCase() ===
					props.groups[i].name.toUpperCase()
				) {
					setDuplicate(true);
					setError(true);
				} else {
				}
			}
		};

		//returns error message
		const getHelperText = () => {
			if (duplicate) {
				return "Group already exists";
			} else if (error) {
				return "Max length 15 characters";
			}
		};

		//creates a group
		const createGroup = (groupName) => {
			Auth.currentAuthenticatedUser().then((response) => {
				var Groups = [];
				if (
					response.signInUserSession.idToken.payload["cognito:groups"] !==
					undefined
				) {
					Groups = response.signInUserSession.idToken.payload["cognito:groups"];
				}
				const requestParams = {
					headers: {
						Authorization: response.signInUserSession.idToken.jwtToken,
					},
					body: {
						Groups: Groups,
						groupDetails: {
							Name: groupName,
							Number: 0,
						},
					},
				};
				return API.post("providers", "/groups-table/name", requestParams)
					.then((response) => {
						if (response.statusCode === 200) {
							var tempGroups = [...props.groups];
							tempGroups.push({ name: groupName, count: 0, active: false });
							var sortedGroups = tempGroups.sort(function (a, b) {
								return a.name.localeCompare(b.name);
							});
							props.setGroups(sortedGroups);
							setSnackbar(true);
						} else if (response.statusCode === 403) {
							setNoAuthorization(true);
							setSnackbar(false);
						} else {
							setSnackbar(false);
						}
					})
					.catch((error) => {
						setNoAuthentication(true);
					});
			});
		};

		return (
			<ThemeProvider theme={styles.themeDefault}>
				<Dialog
					open={props.open}
					onClose={() => {
						props.onClose();
						setError(false);
						setDuplicate(false);
					}}
				>
					<DialogTitle>Add Group</DialogTitle>
					<DialogContent>
						<TextField
							error={error}
							onChange={() => validate()}
							autoComplete="new-password"
							id="groupName"
							label="Group Name"
							helperText={getHelperText()}
						></TextField>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								props.onClose();
								setError(false);
								setDuplicate(false);
							}}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							disabled={error}
							onClick={() => {
								createGroup(document.getElementById("groupName").value);
								props.onClose();
								setError(false);
								setDuplicate(false);
							}}
						>
							Add
						</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={snackbar}
					autoHideDuration={2000}
					onClose={() => setSnackbar(false)}
					message="Group Added"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setSnackbar(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
				<NoAuthentication
					logout={props.logout}
					open={noAuthentication}
					onClose={() => setNoAuthentication(false)}
				/>
				<NoAuthorization
					open={noAuthorization}
					onClose={() => setNoAuthorization(false)}
				/>
			</ThemeProvider>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
