import { Dialog, DialogContent, ThemeProvider } from "@material-ui/core";
import React from "react";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";
import styles from "./Utilities/styles";

//Written by Harrison Norris
export const NoAuthentication = React.memo(
	(props) => {
		return (
			<ThemeProvider theme={styles.themeDefault}>
				<Dialog
					open={props.open}
					onClose={() => props.onClose()}
					disableBackdropClick={true}
					disableEscapeKeyDown={true}
				>
					<DialogTitle>Session Expired</DialogTitle>
					<DialogContent>
						You are not authenticated, please login again
					</DialogContent>
					<DialogActions>
						<Button
							fullWidth
							color="primary"
							onClick={() => {
								props.onClose();
								props.logout();
							}}
						>
							Logout
						</Button>
					</DialogActions>
				</Dialog>
			</ThemeProvider>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
