import React from "react";
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	TextField,
	DialogActions,
	ThemeProvider,
	Grid,
	Snackbar,
	IconButton,
	ListItem,
	ListItemIcon,
	Checkbox,
	ListItemText,
	Divider,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Validate from "../../Utilities/validate";
import FaxFormatter from "../../Utilities/faxFormatter.js";
import styles from "../../Utilities/styles.js";
import DuplicateChecker from "../../Utilities/duplicateManager";
import { NoAuthentication } from "../../notAuthenticated";
import { NoAuthorization } from "../../notAuthorized";
import { API, Auth } from "aws-amplify";

//Written by Harrison Norris
export const AddRecords = React.memo(
	(props) => {
		const [noAuthentication, setNoAuthentication] = React.useState(false);
		const [noAuthorization, setNoAuthorization] = React.useState(false);
		const [showTextFields, setShowTextFields] = React.useState(true);
		const [snackbarOpen, setSnackbarOpen] = React.useState(false);
		const [snackbarMessage, setSnackbarMessage] = React.useState("");
		const [updateReferrals, setUpdateReferrals] = React.useState(true);
		const [provider, setProvider] = React.useState({});
		const [errors, setErrors] = React.useState([
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
		]);
		const [groups, setGroups] = React.useState([]);

		//Reset state
		React.useEffect(() => {
			var tempGroups = [...props.groups];
			for (var i in tempGroups) {
				tempGroups[i].active = false;
			}
			setGroups(tempGroups);
			setShowTextFields(true);
			setProvider({});
		}, [props.open]);

		//validates fields
		const validate = () => {
			var newError = [
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
			];
			var errors = false;
			var emptyCount = 0;
			if (document.getElementById("title_add_record").value.length < 1) {
				emptyCount++;
			}
			if (document.getElementById("fName_add_record").value.length < 1) {
				emptyCount++;
			}
			if (document.getElementById("lName_add_record").value.length < 1) {
				emptyCount++;
			}
			if (document.getElementById("providerNum_add_record").value.length < 1) {
				emptyCount++;
			}
			if (
				!Validate.providerNumber(
					document.getElementById("providerNum_add_record").value
				)
			) {
				newError[3] = true;
				errors = true;
			} else {
				newError[3] = false;
			}
			if (document.getElementById("email_add_record").value.length < 1) {
				emptyCount++;
			}
			if (!Validate.email(document.getElementById("email_add_record").value)) {
				newError[4] = true;
				errors = true;
			} else {
				newError[4] = false;
			}
			if (document.getElementById("practice_add_record").value.length < 1) {
				emptyCount++;
			}
			if (document.getElementById("position_add_record").value.length < 1) {
				emptyCount++;
			}
			if (document.getElementById("address_add_record").value.length < 1) {
				emptyCount++;
			}
			if (document.getElementById("suburb_add_record").value.length < 1) {
				emptyCount++;
			}
			if (
				!Validate.suburb(document.getElementById("suburb_add_record").value)
			) {
				newError[8] = true;
				errors = true;
			} else {
				newError[8] = false;
			}
			if (document.getElementById("state_add_record").value.length < 1) {
				emptyCount++;
			}
			if (!Validate.suburb(document.getElementById("state_add_record").value)) {
				newError[9] = true;
				errors = true;
			} else {
				newError[9] = false;
			}
			if (document.getElementById("postcode_add_record").value.length < 1) {
				emptyCount++;
			}
			if (
				!Validate.postcode(document.getElementById("postcode_add_record").value)
			) {
				newError[10] = true;
				errors = true;
			} else {
				newError[10] = false;
			}
			if (document.getElementById("phone_add_record").value.length < 1) {
				emptyCount++;
			}
			if (!Validate.phone(document.getElementById("phone_add_record").value)) {
				newError[11] = true;
				errors = true;
			} else {
				newError[11] = false;
			}
			if (document.getElementById("fax_add_record").value.length < 1) {
				emptyCount++;
			}
			if (!Validate.fax(document.getElementById("fax_add_record").value)) {
				newError[12] = true;
				errors = true;
			} else {
				newError[12] = false;
			}
			if (
				document.getElementById("title_add_record").value.trim() === "" &&
				document.getElementById("fName_add_record").value.trim() === "" &&
				document.getElementById("lName_add_record").value.trim() === "" &&
				document.getElementById("providerNum_add_record").value.trim() === "" &&
				document.getElementById("practice_add_record").value.trim() === "" &&
				document.getElementById("position_add_record").value.trim() === "" &&
				document.getElementById("address_add_record").value.trim() === "" &&
				document.getElementById("suburb_add_record").value.trim() === "" &&
				document.getElementById("state_add_record").value.trim() === "" &&
				document.getElementById("postcode_add_record").value.trim() === "" &&
				document.getElementById("phone_add_record").value.trim() === "" &&
				document.getElementById("fax_add_record").value.trim() === "" &&
				document.getElementById("email_add_record").value.trim() === ""
			) {
				errors = true;
			}

			setErrors(newError);
			if (emptyCount > 12) {
				errors = true;
			}
			return !errors;
		};
		const getGroups = () => {
			return (
				<div style={{ width: 320 }}>
					<Divider />
					<div style={{ maxHeight: 320, overflow: "auto" }}>
						{groups.map((value, i) => {
							return (
								<ListItem
									key={value.name}
									dense
									button
									onClick={() => {
										var tempGroups = [...groups];
										tempGroups[i].active = !tempGroups[i].active;
										setGroups(tempGroups);
									}}
								>
									<ListItemIcon>
										<Checkbox edge="start" checked={value.active} />
									</ListItemIcon>
									<ListItemText primary={value.name} />
								</ListItem>
							);
						})}
					</div>
					<Divider />
					<ListItem button onClick={() => setUpdateReferrals(!updateReferrals)}>
						<ListItemIcon>
							<Checkbox edge="start" checked={updateReferrals} />
						</ListItemIcon>
						<ListItemText primary="Update referral count" />
					</ListItem>
				</div>
			);
		};

		//creates a provider
		const createProvider = (provider) => {
			Auth.currentAuthenticatedUser().then((response) => {
				var Groups = [];
				if (
					response.signInUserSession.idToken.payload["cognito:groups"] !==
					undefined
				) {
					Groups = response.signInUserSession.idToken.payload["cognito:groups"];
				}
				const requestParams = {
					headers: {
						Authorization: response.signInUserSession.idToken.jwtToken,
					},
					body: {
						User: response.signInUserSession.idToken.payload[
							"cognito:username"
						],
						Groups,
						provider,
					},
				};
				return API.post("providers", "/provider-table/provider", requestParams)
					.then((response) => {
						if (response.statusCode === 200) {
							var tempProviders = [...props.providers];
							provider["ID"] = response.body.ID;
							provider["CreationDate"] = response.body.CreationDate;
							provider["Latitude"] = response.body.lat;
							provider["Longitude"] = response.body.lng;
							provider.selected = false;
							provider.show = true;
							provider.filtered = true;
							tempProviders.push(provider);
							props.setProviders(tempProviders);
							setSnackbarMessage("New record added");
							setSnackbarOpen(true);
						} else if (response.statusCode === 403) {
							setNoAuthorization(true);
							setSnackbarOpen(false);
						} else {
							setSnackbarOpen(false);
						}
					})
					.catch((error) => {
						setNoAuthentication(true);
					});
			});
		};
		const generateProvider = () => {
			var providerGroups = [];
			for (var i in groups) {
				if (groups[i].active) {
					providerGroups.push(groups[i].name);
				}
			}
			if (updateReferrals) {
				provider.Referrals = 1;
			} else {
				provider.Referrals = 0;
			}
			provider.Group = providerGroups;

			var duplicateIndex = DuplicateChecker.checkDuplicates(
				provider,
				props.providers
			);
			if (duplicateIndex === null) {
				createProvider(provider);
				props.onClose();
			} else {
				var updatedProvider = DuplicateChecker.updateDuplicateRecord(
					provider,
					duplicateIndex,
					updateReferrals,
					providerGroups
				);
				updateProvider(updatedProvider);
				props.onClose();
			}
		};

		// Amplify API Call to PATCH provider-table/provider record in the database
		const updateProvider = (updatedAttributes) => {
			const ID = updatedAttributes.ID;
			const Parameter = updatedAttributes.Parameter;
			const Value = updatedAttributes.Value;
			Auth.currentAuthenticatedUser().then((response) => {
				const requestParams = {
					headers: {
						Authorization: response.signInUserSession.idToken.jwtToken,
					},
					body: {
						User: response.signInUserSession.idToken.payload[
							"cognito:username"
						],
						Groups:
							response.signInUserSession.idToken.payload["cognito:groups"],
						ID,
						Parameter,
						Value,
					},
				};
				API.patch("providers", "/provider-table/provider", requestParams)
					.then((response) => {
						if (response.statusCode === 200) {
							if (response.body !== "Nothing was updated") {
								setSnackbarMessage("Duplicated record updated");
								setSnackbarOpen(true);
							}
							props.setProvider(response.body, false);
						} else if (response.statusCode === 403) {
							setNoAuthorization(true);
							setSnackbarOpen(false);
						} else {
							setSnackbarOpen(false);
						}
					})
					.catch((error) => {
						setNoAuthentication(true);
					});
			});
		};

		const toTitleCase = (str) => {
			return str.replace(/\w\S*/g, function (txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			});
		};
		const generateProviderJSON = () => {
			var tempProvider = {
				ProviderNumber: document.getElementById("providerNum_add_record").value,
				Title: Validate.toTitleCase(
					document.getElementById("title_add_record").value
				),
				Street: Validate.toTitleCase(
					document.getElementById("address_add_record").value
				),
				Suburb: Validate.toTitleCase(
					document.getElementById("suburb_add_record").value
				),
				PostCode: document
					.getElementById("postcode_add_record")
					.value.toUpperCase(),
				State: document.getElementById("state_add_record").value,
				Group: [],
				Practice: Validate.toTitleCase(
					document.getElementById("practice_add_record").value
				),
				Position: Validate.toTitleCase(
					document.getElementById("position_add_record").value
				),
				FirstName: Validate.toTitleCase(
					document.getElementById("fName_add_record").value
				),
				Active: true,
				LastName: Validate.toTitleCase(
					document.getElementById("lName_add_record").value
				),
				Email: document.getElementById("email_add_record").value,
				Fax: FaxFormatter(document.getElementById("fax_add_record").value),
				Phone: document.getElementById("phone_add_record").value,
			};
			setProvider(tempProvider);
		};
		const getTextFields = () => {
			return (
				<>
					<Grid container spacing={3}>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="title_add_record"
								label="Title"
								style={{ width: 56 }}
								error={errors[0]}
							/>
						</Grid>
						<Grid item xs={5}>
							<TextField
								autoComplete="new-password"
								id="fName_add_record"
								label="First Name"
								fullWidth
								error={errors[1]}
							/>
						</Grid>
						<Grid item xs={5}>
							<TextField
								autoComplete="new-password"
								id="lName_add_record"
								label="Last Name"
								fullWidth
								error={errors[2]}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="providerNum_add_record"
								label="Provider Number"
								fullWidth
								onChange={() => {
									if (errors[3]) {
										validate();
									}
								}}
								error={errors[3]}
							/>
						</Grid>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="email_add_record"
								label="Email"
								fullWidth
								error={errors[4]}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="practice_add_record"
								label="Practice"
								fullWidth
								error={errors[5]}
							/>
						</Grid>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="position_add_record"
								label="Position"
								fullWidth
								error={errors[6]}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="address_add_record"
								label="Address"
								fullWidth
								error={errors[7]}
							/>
						</Grid>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="suburb_add_record"
								label="Suburb"
								fullWidth
								onChange={() => {
									if (errors[8]) {
										validate();
									}
								}}
								error={errors[8]}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="state_add_record"
								label="State"
								fullWidth
								onChange={() => {
									if (errors[9]) {
										validate();
									}
								}}
								error={errors[9]}
							/>
						</Grid>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="postcode_add_record"
								label="Postcode"
								fullWidth
								onChange={() => {
									if (errors[10]) {
										validate();
									}
								}}
								error={errors[10]}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="phone_add_record"
								label="Phone"
								fullWidth
								onChange={() => {
									if (errors[11]) {
										validate();
									}
								}}
								error={errors[11]}
							/>
						</Grid>
						<Grid item xs>
							<TextField
								autoComplete="new-password"
								id="fax_add_record"
								label="Fax"
								fullWidth
								onChange={() => {
									if (errors[12]) {
										validate();
									}
								}}
								error={errors[12]}
							/>
						</Grid>
					</Grid>
				</>
			);
		};
		return (
			<>
				<ThemeProvider theme={styles.themeDefault}>
					<Dialog open={props.open} onClose={() => props.onClose()}>
						<DialogTitle>Add Record</DialogTitle>
						<DialogContent>
							{showTextFields ? getTextFields() : getGroups()}
						</DialogContent>
						<DialogActions>
							<Button onClick={() => props.onClose()}>Cancel</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									if (showTextFields) {
										if (validate()) {
											generateProviderJSON();
											setShowTextFields(false);
										}
									} else {
										generateProvider();
									}
								}}
							>
								{showTextFields ? "Next" : "Save"}
							</Button>
						</DialogActions>
					</Dialog>
				</ThemeProvider>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={snackbarOpen}
					autoHideDuration={2000}
					onClose={() => setSnackbarOpen(false)}
					message={snackbarMessage}
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setSnackbarOpen(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
				<NoAuthentication
					logout={props.logout}
					open={noAuthentication}
					onClose={() => setNoAuthentication(false)}
				/>
				<NoAuthorization
					open={noAuthorization}
					onClose={() => setNoAuthorization(false)}
				/>
			</>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
