import React from "react";
import {
	TextField,
	Dialog,
	DialogContent,
	Grid,
	Button,
	DialogActions,
	DialogTitle,
} from "@material-ui/core";
import Validate from "../../Utilities/validate";

//Written by Harrison Norris
export default function EditProvider(props) {
	const [errors, setErrors] = React.useState([]);

	//validates fields
	const validate = () => {
		var newError = [
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
			false,
		];
		var errors = false;

		if (
			!Validate.providerNumber(
				document.getElementById("providerNum_add_record").value
			)
		) {
			newError[3] = true;
			errors = true;
		} else {
			newError[3] = false;
		}

		if (!Validate.email(document.getElementById("email_add_record").value)) {
			newError[4] = true;
			errors = true;
		} else {
			newError[4] = false;
		}

		if (!Validate.suburb(document.getElementById("suburb_add_record").value)) {
			newError[8] = true;
			errors = true;
		} else {
			newError[8] = false;
		}

		if (!Validate.suburb(document.getElementById("state_add_record").value)) {
			newError[9] = true;
			errors = true;
		} else {
			newError[9] = false;
		}

		if (
			!Validate.postcode(document.getElementById("postcode_add_record").value)
		) {
			newError[10] = true;
			errors = true;
		} else {
			newError[10] = false;
		}

		if (!Validate.phone(document.getElementById("phone_add_record").value)) {
			newError[11] = true;
			errors = true;
		} else {
			newError[11] = false;
		}

		if (!Validate.fax(document.getElementById("fax_add_record").value)) {
			newError[12] = true;
			errors = true;
		} else {
			newError[12] = false;
		}

		setErrors(newError);
		return !errors;
	};
	return (
		<Dialog open={props.open} onClose={() => props.onClose()}>
			<DialogTitle>Edit Record</DialogTitle>
			<DialogContent>
				<Grid container spacing={3}>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="title_add_record"
							label="Title"
							style={{ width: 56 }}
							defaultValue={props.provider != null ? props.provider.Title : ""}
							error={errors[0]}
						/>
					</Grid>
					<Grid item xs={5}>
						<TextField
							autoComplete="new-password"
							id="fName_add_record"
							label="First Name"
							fullWidth
							defaultValue={
								props.provider != null ? props.provider.FirstName : ""
							}
							error={errors[1]}
						/>
					</Grid>
					<Grid item xs={5}>
						<TextField
							autoComplete="new-password"
							id="lName_add_record"
							label="Last Name"
							fullWidth
							defaultValue={
								props.provider != null ? props.provider.LastName : ""
							}
							error={errors[2]}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="providerNum_add_record"
							label="Provider Number"
							fullWidth
							defaultValue={
								props.provider != null ? props.provider.ProviderNumber : ""
							}
							onChange={() => {
								if (errors[3]) {
									validate();
								}
							}}
							error={errors[3]}
						/>
					</Grid>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="email_add_record"
							label="Email"
							fullWidth
							defaultValue={props.provider != null ? props.provider.Email : ""}
							onChange={() => {
								if (errors[4]) {
									validate();
								}
							}}
							error={errors[4]}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="organisation_add_record"
							label="Organisation"
							fullWidth
							defaultValue={
								props.provider != null ? props.provider.Practice : ""
							}
							error={errors[5]}
						/>
					</Grid>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="position_add_record"
							label="Position"
							fullWidth
							defaultValue={
								props.provider != null ? props.provider.Position : ""
							}
							error={errors[6]}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="address_add_record"
							label="Address"
							fullWidth
							defaultValue={props.provider != null ? props.provider.Street : ""}
							error={errors[7]}
						/>
					</Grid>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="suburb_add_record"
							label="Suburb"
							fullWidth
							defaultValue={props.provider != null ? props.provider.Suburb : ""}
							onChange={() => {
								if (errors[8]) {
									validate();
								}
							}}
							error={errors[8]}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="state_add_record"
							label="State"
							fullWidth
							defaultValue={props.provider != null ? props.provider.State : ""}
							onChange={() => {
								if (errors[9]) {
									validate();
								}
							}}
							error={errors[9]}
						/>
					</Grid>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="postcode_add_record"
							label="Postcode"
							fullWidth
							defaultValue={
								props.provider != null ? props.provider.PostCode : ""
							}
							onChange={() => {
								if (errors[10]) {
									validate();
								}
							}}
							error={errors[10]}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="phone_add_record"
							label="Phone"
							fullWidth
							defaultValue={props.provider != null ? props.provider.Phone : ""}
							onChange={() => {
								if (errors[11]) {
									validate();
								}
							}}
							error={errors[11]}
						/>
					</Grid>
					<Grid item xs>
						<TextField
							autoComplete="new-password"
							id="fax_add_record"
							label="Fax"
							fullWidth
							defaultValue={props.provider != null ? props.provider.Fax : ""}
							onChange={() => {
								if (errors[12]) {
									validate();
								}
							}}
							error={errors[12]}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => props.onClose()}>Cancel</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						if (validate()) {
							//Save changes
							props.saveEdit(
								document.getElementById("title_add_record").value,
								document.getElementById("fName_add_record").value,
								document.getElementById("lName_add_record").value,
								document.getElementById("providerNum_add_record").value,
								document.getElementById("email_add_record").value,
								document.getElementById("organisation_add_record").value,
								document.getElementById("position_add_record").value,
								document.getElementById("address_add_record").value,
								document.getElementById("suburb_add_record").value,
								document.getElementById("state_add_record").value,
								document.getElementById("postcode_add_record").value,
								document.getElementById("phone_add_record").value,
								document.getElementById("fax_add_record").value
							);
						}
					}}
				>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
}
