import React from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	List,
	ListItem,
	ListItemText,
	DialogActions,
	Snackbar,
	IconButton,
	ListItemSecondaryAction,
	Select,
	MenuItem,
	Tooltip,
} from "@material-ui/core";
import { Close, Delete, Replay } from "@material-ui/icons";
import { API, Auth } from "aws-amplify";
import { NoAuthentication } from "../../notAuthenticated";
import { NoAuthorization } from "../../notAuthorized";

//Written by Harrison Norris
export const ManageUsers = React.memo(
	(props) => {
		const [userDeletedSnackbar, setUserDeletedSnackbar] = React.useState(false);
		const [MFAResetSnackbar, setMFAResetSnackbar] = React.useState(false);
		const [deletingUser, setDeletingUser] = React.useState(null);
		const [deleting, setDeleting] = React.useState(false);
		const [users, setUsers] = React.useState([]);
		const [noAuthentication, setNoAuthentication] = React.useState(false);
		const [noAuthorization, setNoAuthorization] = React.useState(false);

		React.useEffect(() => {
			readUsers();
		}, []);

		const readUsers = () => {
			Auth.currentAuthenticatedUser()
				.then((response) => {
					var access = "";
					if (
						response.signInUserSession.idToken.payload["cognito:groups"] ===
						undefined
					) {
						access = "false";
					} else if (
						response.signInUserSession.idToken.payload["cognito:groups"][0] ===
						"admin"
					) {
						access = "true";
					}
					const requestParams = {
						headers: {
							Authorization: response.signInUserSession.idToken.jwtToken,
						},
					};
					return API.get(
						"providers",
						"/cognito-user-pool?access=" + access,
						requestParams
					).then((response) => {
						if (response.statusCode === 200) {
							setUsers(response.body);
						}
						if (response.statusCode === 403) {
							setNoAuthorization(true);
						}
					});
				})
				.catch((error) => {
					setNoAuthentication(true);
				});
		};

		const updateUserGroup = (username, newGroup) => {
			Auth.currentAuthenticatedUser()
				.then((response) => {
					var Groups = [];
					if (
						response.signInUserSession.idToken.payload["cognito:groups"] !==
						undefined
					) {
						Groups =
							response.signInUserSession.idToken.payload["cognito:groups"];
					}
					const requestParams = {
						headers: {
							Authorization: response.signInUserSession.idToken.jwtToken,
						},
						body: {
							Groups: Groups,
							NewCognitoGroup: newGroup,
						},
					};
					return API.patch(
						"providers",
						"/cognito-user-pool/" + username,
						requestParams
					).then((response) => {
						if (response.statusCode === 200) {
							var tempUsers = [...users];
							for (var i = 0; i < users.length; i++) {
								if (users[i].username === username) {
									tempUsers[i].permission = newGroup;
								}
							}
							setUsers(tempUsers);
						}
						if (response.statusCode === 403) {
							setNoAuthorization(true);
						}
					});
				})
				.catch((error) => {
					setNoAuthentication(true);
				});
		};

		const deleteUser = (username) => {
			Auth.currentAuthenticatedUser()
				.then((response) => {
					var Groups = [];
					if (
						response.signInUserSession.idToken.payload["cognito:groups"] !==
						undefined
					) {
						Groups =
							response.signInUserSession.idToken.payload["cognito:groups"];
					}
					const requestParams = {
						headers: {
							Authorization: response.signInUserSession.idToken.jwtToken,
						},
						body: {
							Groups: Groups,
						},
					};
					return API.del(
						"providers",
						"/cognito-user-pool/" + username,
						requestParams
					).then((response) => {
						if (response.statusCode === 200) {
							var index = -1;
							for (var i = 0; i < users.length; i++) {
								if (users[i].username === username) {
									index = i;
								}
							}
							if (index > -1) {
								var newUsers = [...users];
								newUsers.splice(index, 1);
								setUsers(newUsers);
							}
						}
						if (response.statusCode === 403) {
							setNoAuthorization(true);
						}
					});
				})
				.catch((error) => {
					setNoAuthentication(true);
				});
		};

		const resetMFA = (username) => {
			Auth.currentAuthenticatedUser()
				.then((response) => {
					var Groups = [];
					if (
						response.signInUserSession.idToken.payload["cognito:groups"] !==
						undefined
					) {
						Groups =
							response.signInUserSession.idToken.payload["cognito:groups"];
					}
					const requestParams = {
						headers: {
							Authorization: response.signInUserSession.idToken.jwtToken,
						},
						body: {
							Groups: Groups,
						},
					};
					return API.del(
						"providers",
						"/cognito-user-pool/" + username + "/mfa",
						requestParams
					).then((response) => {
						if (response.statusCode === 200) {
							setMFAResetSnackbar(true);
						}
						if (response.statusCode === 403) {
							setNoAuthorization(true);
						}
					});
				})
				.catch((error) => {
					setNoAuthentication(true);
				});
		};

		const getDeleting = () => {
			return (
				<>
					<DialogTitle>Delete User - {deletingUser.username}</DialogTitle>
					<DialogContent>
						Are you sure you want to delete user: {deletingUser.username}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setDeleting(false)}>Cancel</Button>
						<Button
							variant="contained"
							style={{ backgroundColor: "#E73E33", color: "white" }}
							onClick={() => {
								setDeleting(false);
								setUserDeletedSnackbar(true);
								deleteUser(deletingUser.username);
							}}
						>
							Delete
						</Button>
					</DialogActions>
				</>
			);
		};
		const getUsers = () => {
			readUsers();
			return (
				<>
					<DialogTitle>Manage Users</DialogTitle>
					<DialogContent style={{ maxHeight: 300 }}>
						<List>
							{users.map((user, i) => {
								return (
									<ListItem key={user.username}>
										<ListItemText>{user.username}</ListItemText>
										<Select
											style={{ marginLeft: 50, width: 120 }}
											labelId="demo-simple-select-label"
											value={user.permission}
										>
											<MenuItem
												value={"admin"}
												onClick={() => {
													updateUserGroup(user.username, "admin");
												}}
											>
												Admin
											</MenuItem>
											<MenuItem
												value={"read/write"}
												onClick={() => {
													updateUserGroup(user.username, "read/write");
												}}
											>
												Read/Write
											</MenuItem>
											<MenuItem
												value={"read"}
												onClick={() => {
													updateUserGroup(user.username, "read");
												}}
											>
												Read
											</MenuItem>
										</Select>
										<Tooltip title="Reset MFA" arrow>
											<IconButton
												onClick={() => {
													resetMFA(user.username);
												}}
												style={{ marginRight: 15 }}
											>
												<Replay />
											</IconButton>
										</Tooltip>
										<ListItemSecondaryAction>
											<Tooltip title="Delete User" arrow>
												<IconButton
													onClick={() => {
														setDeletingUser(user);
														setDeleting(true);
													}}
												>
													<Delete />
												</IconButton>
											</Tooltip>
										</ListItemSecondaryAction>
									</ListItem>
								);
							})}
						</List>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => props.onClose()}>Close</Button>
					</DialogActions>
				</>
			);
		};

		return (
			<>
				<NoAuthentication
					logout={props.logout}
					open={noAuthentication}
					onClose={() => setNoAuthentication(false)}
				/>
				<NoAuthorization
					open={noAuthentication && props.open}
					onClose={() => setNoAuthorization(false)}
				/>
				<Dialog open={props.open} onClose={() => props.onClose()}>
					{deleting ? getDeleting() : getUsers()}
				</Dialog>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={userDeletedSnackbar}
					autoHideDuration={2000}
					onClose={() => setUserDeletedSnackbar(false)}
					message="User deleted"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setUserDeletedSnackbar(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={MFAResetSnackbar}
					autoHideDuration={2000}
					onClose={() => setMFAResetSnackbar(false)}
					message="User MFA Reset"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setMFAResetSnackbar(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
			</>
		);
	},
	(prev, next) => {
		if (next.open !== prev.open) {
			return false;
		} else {
			return true;
		}
	}
);
