import React from "react";
import {
	Paper,
	TextField,
	Button,
	ThemeProvider,
	Typography,
	CircularProgress,
	createMuiTheme,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@material-ui/core";
import { Auth } from "aws-amplify";
import "./login.css";
import TabBar from "./tabs";
import styles from "./Utilities/styles";
import Validate from "./Utilities/validate";
import { ForgotPassword } from "./Settings/Dialogs/forgotPassword";
import QRCode from "qrcode.react";
//Written by Harrison Norris
export default function Login(props) {
	const [loginPressed, setLoginPressed] = React.useState(false);
	const [authenticated, setAuthenticated] = React.useState(false);
	const [loginFailed, setLoginFailed] = React.useState(false);
	const [userNotConfirmed, setUserNotConfirmed] = React.useState(false);
	const [setupMFA, setSetupMFA] = React.useState(false);
	const [forgot, setForgot] = React.useState(false);
	const [qrcode, setQrcode] = React.useState(null);
	const [promptMFA, setPromptMFA] = React.useState(false);
	const [MFAFailed, setMFAFailed] = React.useState(false);
	const [errors, setErrors] = React.useState({
		username: false,
		password: false,
	});
	const [user, setUser] = React.useState(null);

	React.useEffect(() => {
		Auth.currentAuthenticatedUser().then((user) => {
			setAuthenticated(true);
			setUser(user);
		});
	}, []);

	//signs in using AWS Amplify
	const signIn = (username, password) => {
		setLoginPressed(true);
		setLoginFailed(false);
		setUserNotConfirmed(false);

		Auth.signIn(username, password)
			.then((user) => {
				setLoginPressed(false);
				setUser(user);
				if (user.preferredMFA === "NOMFA") {
					const issuer =
						"https://cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_uFIQa7QEl";
					Auth.setupTOTP(user).then((code) => {
						setQrcode(
							"otpauth://totp/AWSCognito:" +
								user.username +
								"?secret=" +
								code +
								"&issuer=" +
								issuer
						);
						setSetupMFA(true);
					});
				} else {
					setMFAFailed(false);
					setPromptMFA(true);
				}
			})
			.catch((error) => {
				if (error.code === "NotAuthorizedException") {
					setLoginFailed(true);
				} else if (error.code === "UserNotConfirmedException") {
					setUserNotConfirmed(true);
				}
				setUser(null);
				setLoginPressed(false);
			});
	};

	//Confirm signin using an MFA code
	const confirmSignIn = () => {
		Auth.confirmSignIn(
			user,
			document.getElementById("mfacode").value,
			"SOFTWARE_TOKEN_MFA"
		)
			.then((user) => {
				setAuthenticated(true);
				setUser(user);
				setPromptMFA(false);
			})
			.catch((error) => {
				if (error.code === "NotAuthorizedException") {
					setLoginFailed(true);
					setPromptMFA(false);
				}
				if (error.code === "CodeMismatchException") {
					setMFAFailed(true);
				} else {
					setPromptMFA(false);
				}
			});
	};

	//Verify a new MFA device
	const verifyMFA = () => {
		Auth.verifyTotpToken(
			user,
			document.getElementById("mfacodesetup").value
		).then(() => {
			Auth.setPreferredMFA(user, "TOTP");
			setAuthenticated(true);
			setSetupMFA(false);
		});
	};

	//Remove an MFA device
	const deleteMFA = () => {};
	//Signs out and renders login page
	const logout = () => {
		Auth.signOut();
		setUser(null);
		setLoginPressed(false);
		setAuthenticated(false);
		setLoginFailed(false);
	};

	//validates login fields
	const validateFields = () => {
		var newErrors = errors;
		if (document.getElementById("username").value.length === 0) {
			newErrors.username = true;
		} else {
			newErrors.username = false;
		}
		if (!Validate.password(document.getElementById("password").value)) {
			newErrors.password = true;
		} else {
			newErrors.password = false;
		}
		setErrors({ username: newErrors.username, password: newErrors.password });
		if (newErrors.username || newErrors.password) {
			return false;
		} else {
			return true;
		}
	};

	//handle enter key press
	const handleKeyDown = (event, origin) => {
		switch (origin) {
			case 0:
				if (event.key === "Enter") {
					if (validateFields()) {
						signIn(
							document.getElementById("username").value,
							document.getElementById("password").value
						);
					}
				}
				break;
			case 1:
				if (event.key === "Enter") {
					confirmSignIn();
				}
				break;
			case 2:
				if (event.key === "Enter") {
					verifyMFA();
				}
				break;
			default:
				break;
		}
	};

	//render
	if (authenticated) {
		return <TabBar logout={() => logout()} user={user} />;
	} else {
		return (
			<ThemeProvider theme={styles.themeDefault}>
				<div id="background">
					<ForgotPassword open={forgot} onClose={() => setForgot(false)} />
					<div className="circle" id="circle1" style={{ opacity: "20%" }}></div>
					<div className="circle" id="circle2" style={{ opacity: "20%" }}></div>
					<div className="circle" id="circle3" style={{ opacity: "20%" }}></div>
					<div className="circle" id="circle4" style={{ opacity: "20%" }}></div>
					<div className="circle" id="circle5" style={{ opacity: "20%" }}></div>
					<img
						id="crm-tech"
						src="crm-technologies.png"
						alt="CRM Technologies"
					/>
					<Paper id="card">
						<img id="logo-login" src="logo.png" alt="Logo" />
						<br />
						{loginPressed ? (
							<>
								<Typography style={{ marginBottom: 16 }}>Loging In</Typography>
								<CircularProgress />
							</>
						) : (
							<>
								<TextField
									label="User Name"
									fullWidth
									id="username"
									autoComplete="username"
									error={errors.username}
									helperText={errors.username ? "Cannot be blank" : null}
									onChange={() => {
										if (errors.username) {
											validateFields();
										}
									}}
								/>
								<br />
								<TextField
									label="Password"
									fullWidth
									type="password"
									id="password"
									autoComplete="current-password"
									error={errors.password}
									helperText={
										errors.password
											? "Password must be at least 8 characters and contain a mix of uppercase and lowecase letters, numbers and symbols"
											: null
									}
									onKeyDown={(e) => handleKeyDown(e, 0)}
									onChange={() => {
										if (errors.password) {
											validateFields();
										}
									}}
								/>
								<br />
								{loginFailed ? (
									<div style={{ color: "#E73E33" }}>
										Incorrect username or password
									</div>
								) : null}
								{userNotConfirmed ? (
									<div style={{ color: "#E73E33" }}>Confirm user via email</div>
								) : null}
								<button
									style={{ border: 0, backgroundColor: "transparent" }}
									onClick={() => setForgot(true)}
								>
									Forgot Password
								</button>
								<Button
									color="primary"
									style={{ marginTop: 16 }}
									onClick={() => {
										if (validateFields()) {
											signIn(
												document.getElementById("username").value,
												document.getElementById("password").value
											);
										}
									}}
									fullWidth
								>
									Login
								</Button>
							</>
						)}
					</Paper>
					<Dialog
						open={promptMFA}
						onClose={() => setPromptMFA(false)}
						disableBackdropClick={true}
						disableEscapeKeyDown={true}
					>
						<DialogTitle>Multi-Factor Authentication</DialogTitle>
						<DialogContent>
							<TextField
								fullWidth
								label="Code"
								id="mfacode"
								autoComplete="new-password"
								onKeyDown={(e) => handleKeyDown(e, 1)}
							/>
							{MFAFailed ? (
								<div style={{ color: "#E73E33" }}>Incorrect code</div>
							) : null}
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setPromptMFA(false)}>Cancel</Button>
							<Button
								onClick={() => confirmSignIn()}
								variant="contained"
								color="primary"
							>
								Sign-In
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={setupMFA}
						onClose={() => setSetupMFA(false)}
						disableBackdropClick={true}
						disableEscapeKeyDown={true}
					>
						<DialogTitle>Setup Multi-Factor Authentication</DialogTitle>
						<DialogContent>
							<div style={{ textAlign: "center" }}>
								<QRCode value={qrcode} />
							</div>

							<br />
							<TextField
								onKeyDown={(e) => handleKeyDown(e, 2)}
								label="Enter authentication code"
								id="mfacodesetup"
								autoComplete="new-password"
								fullWidth
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => setSetupMFA(false)}>Cancel</Button>
							<Button
								onClick={() => verifyMFA()}
								variant="contained"
								color="primary"
							>
								Setup
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			</ThemeProvider>
		);
	}
}
