import React from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	DialogActions,
	Snackbar,
	IconButton,
	CircularProgress,
} from "@material-ui/core";
import { Close, Delete } from "@material-ui/icons";
import { API, Auth } from "aws-amplify";
import { NoAuthentication } from "../../notAuthenticated";
import { NoAuthorization } from "../../notAuthorized";

//Written by Sarah Swyer and Harrison Norris
export const DeleteGroup = React.memo(
	(props) => {
		const [noAuthentication, setNoAuthentication] = React.useState(false);
		const [noAuthorization, setNoAuthorization] = React.useState(false);
		const [showList, setShowList] = React.useState(true);
		const [dataLoaded, setDataLoaded] = React.useState(false);
		const [deleting, setDeleting] = React.useState(null);
		const [groupDeletedSnackbar, setGroupDeletedSnackbar] =
			React.useState(false);

		// Loads state
		React.useEffect(() => {
			setDataLoaded(true);
		}, []);

		//set show group list on close and open
		React.useEffect(() => {
			if (props.open) {
				setShowList(true);
			}
		}, [props.open]);

		//Deletes a group, given the group name
		const deleteGroup = (group) => {
			Auth.currentAuthenticatedUser()
				.then((response) => {
					var Groups = [];
					if (
						response.signInUserSession.idToken.payload["cognito:groups"] !==
						undefined
					) {
						Groups =
							response.signInUserSession.idToken.payload["cognito:groups"];
					}
					const requestParams = {
						headers: {
							Authorization: response.signInUserSession.idToken.jwtToken,
						},
						body: {
							Groups: Groups,
						},
					};
					return API.del(
						"providers",
						"/groups-table/" + group.name,
						requestParams
					).then((response) => {
						if (response.statusCode === 200) {
							var index = -1;
							var groups = props.groups;
							for (var i = 0; i < groups.length; i++) {
								if (groups[i].name === group.name) {
									index = i;
								}
							}
							if (index > -1) {
								groups.splice(index, 1);
								const tempGroups = [...groups]; 
								props.setGroups(tempGroups);
							}
							props.onClose();
							setGroupDeletedSnackbar(true);
						}
						if (response.statusCode === 403) {
							setNoAuthorization(true);
						}
					});
				})
				.catch((error) => {
					setNoAuthentication(true);
				});
		};
		//returns group list if loaded
		const getList = () => {
			if (dataLoaded) {
				return (
					<List style={{ width: 250, maxHeight: 300, overflow: "auto" }}>
						{props.groups.map((group, i) => {
							return (
								<ListItem key={group.name}>
									<ListItemIcon>
										<IconButton
											onClick={() => {
												setShowList(false);
												setDeleting(group);
											}}
										>
											<Delete />
										</IconButton>
									</ListItemIcon>
									<ListItemText primary={group.name} />
								</ListItem>
							);
						})}
					</List>
				);
			} else {
				return <CircularProgress />;
			}
		};
		return (
			<>
				<NoAuthentication
					logout={props.logout}
					open={noAuthentication && props.open}
					onClose={() => setNoAuthentication(false)}
				/>
				<NoAuthorization
					open={noAuthorization}
					onClose={() => setNoAuthorization(false)}
				/>
				<Dialog open={props.open} onClose={() => props.onClose(false)}>
					<DialogTitle>Delete Group</DialogTitle>
					<DialogContent>
						{showList
							? getList()
							: "Are you sure you want to delete group " + deleting.name + "?"}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								if (showList) {
									props.onClose(false);
								}
								setShowList(true);
							}}
						>
							{showList ? "Close" : "Back"}
						</Button>
						{showList ? null : (
							<Button
								variant="contained"
								style={{ backgroundColor: "#E73E33", color: "white" }}
								onClick={() => deleteGroup(deleting)}
							>
								Delete
							</Button>
						)}
					</DialogActions>
				</Dialog>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={groupDeletedSnackbar}
					autoHideDuration={2000}
					onClose={() => setGroupDeletedSnackbar(false)}
					message="Group deleted"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setGroupDeletedSnackbar(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
			</>
		);
	},
	(prev, next) => {
		if (next.open !== prev.open) {
			return false;
		} else {
			return true;
		}
	}
);
