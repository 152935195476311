import React from "react";
import {
	List,
	ListItemIcon,
	ListItem,
	ListItemText,
	Switch,
	Paper,
	ThemeProvider,
	ListItemSecondaryAction,
	IconButton,
} from "@material-ui/core";
import {
	AccountCircle,
	Lock,
	ArrowRight,
	Group,
	Person,
	Delete,
} from "@material-ui/icons";
import styles from "../Utilities/styles";
import { CreateUser } from "./Dialogs/newUser";
import { DeleteGroup } from "./Dialogs/deleteGroup";
import { ResetPassword } from "./Dialogs/resetPassword";
import { ManageUsers } from "./Dialogs/manageUsers";
import "./settings.css";
import Auth from "@aws-amplify/auth";

//Written by Harrison Norris
export default function Settings(props) {
	const [deleteGroupDialog, setDeleteGroupDialog] = React.useState(false);
	const [newUserDialog, setNewUserDialog] = React.useState(false);
	const [resetPasswordDialog, setResetPasswordDialog] = React.useState(false);
	const [manageUsersDialog, setManageUsersDialog] = React.useState(false);
	const [userIsAdmin, setUserIsAdmin] = React.useState(false);

	React.useEffect(() => {
		Auth.currentAuthenticatedUser().then((user) => {
			if (
				user.signInUserSession.idToken.payload["cognito:groups"] !== undefined &&
				user.signInUserSession.idToken.payload["cognito:groups"][0] === "admin"
			) {
				setUserIsAdmin(true);
			} else {
				setUserIsAdmin(false);
			}
		});
	}, []);

	return (
		<>
			<ThemeProvider theme={styles.themeDefault}>
				<DeleteGroup
					open={deleteGroupDialog}
					onClose={() => setDeleteGroupDialog(false)}
					groups={props.groups}
					setGroups={props.setGroups}
					aria-label="Delete Group"
					logout={props.logout}
				/>
				<CreateUser
					open={newUserDialog}
					onClose={() => setNewUserDialog(false)}
					aria-label="Create User"
				/>
				<ResetPassword
					open={resetPasswordDialog}
					onClose={() => setResetPasswordDialog(false)}
					aria-label="Reset Password"
				/>
				<ManageUsers
					open={manageUsersDialog}
					onClose={() => setManageUsersDialog(false)}
					logout={props.logout}
				/>

				<div id="settings">
					<div className="circle" id="circle1" style={{ opacity: "20%" }}></div>
					<div className="circle" id="circle2" style={{ opacity: "20%" }}></div>
					<div className="circle" id="circle3" style={{ opacity: "20%" }}></div>
					<div className="circle" id="circle4" style={{ opacity: "20%" }}></div>
					<div className="circle" id="circle5" style={{ opacity: "20%" }}></div>
					<div id="cardsContainer">
						<div>
							<h2 className="settingsHeader">Database</h2>
							<Paper className="settingsCard">
								<List>
									<ListItem
										divider
										button
										onClick={() => props.setShowInactive()}
									>
										<ListItemIcon>
											<Person />
										</ListItemIcon>
										<ListItemText>
											Show inactive providers in database
										</ListItemText>
										<ListItemSecondaryAction>
											<Switch
												checked={props.showInactive}
												onClick={() => props.setShowInactive()}
												aria-label="Toggle inactive users"
											/>
										</ListItemSecondaryAction>
									</ListItem>

									<ListItem
										disabled={!userIsAdmin}
										button
										onClick={() => {
											setDeleteGroupDialog(true);
										}}
									>
										<ListItemIcon>
											<Delete />
										</ListItemIcon>
										<ListItemText>Delete Group</ListItemText>
										<ListItemSecondaryAction>
											<IconButton
												disabled={!userIsAdmin}
												onClick={() => {
													setDeleteGroupDialog(true);
												}}
											>
												<ArrowRight />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</List>
							</Paper>
						</div>

						<div>
							<h2 className="settingsHeader">Accounts</h2>
							<Paper className="settingsCard">
								<List>
									<ListItem
										divider
										button
										onClick={() => setResetPasswordDialog(true)}
									>
										<ListItemIcon>
											<Lock />
										</ListItemIcon>
										<ListItemText>Reset your password</ListItemText>
										<ListItemSecondaryAction>
											<IconButton onClick={() => setResetPasswordDialog(true)}>
												<ArrowRight />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
									<ListItem
										divider
										button
										disabled={!userIsAdmin}
										onClick={() => setNewUserDialog(true)}
									>
										<ListItemIcon>
											<AccountCircle />
										</ListItemIcon>
										<ListItemText>Add new user account</ListItemText>
										<ListItemSecondaryAction>
											<IconButton
												disabled={!userIsAdmin}
												onClick={() => {
													setNewUserDialog(true);
												}}
											>
												<ArrowRight />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>

									<ListItem
										button
										disabled={!userIsAdmin}
										onClick={() => setManageUsersDialog(true)}
									>
										<ListItemIcon>
											<Group />
										</ListItemIcon>
										<ListItemText>Manage users</ListItemText>
										<ListItemSecondaryAction>
											<IconButton
												disabled={!userIsAdmin}
												onClick={() => setManageUsersDialog(true)}
											>
												<ArrowRight />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</List>
							</Paper>
						</div>
					</div>
				</div>
			</ThemeProvider>
		</>
	);
}
