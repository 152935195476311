import { Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import { DialogTitle, DialogActions, Button } from "@material-ui/core";

//Written by Sarah Swyer
export const NoAuthorization = React.memo(
	(props) => {
		return (
			<Dialog open={props.open} onClose={() => props.onClose()}>
				<DialogTitle>No Permission</DialogTitle>
				<DialogContent>
					You do not have permission to perform this action.
				</DialogContent>
				<DialogActions>
					<Button
						fullWidth
						color="primary"
						onClick={() => {
							props.onClose();
						}}
					>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
