import React from "react";
import "./tabs.css";
import Database from "./Database/database.js";
import { API, Auth } from "aws-amplify";
import Dashboard from "./Dashboard/dashboard.js";
import Settings from "./Settings/settings.js";
import {
	AppBar,
	Tabs,
	Tab,
	ThemeProvider,
	Toolbar,
	Button,
	withStyles,
} from "@material-ui/core";
import styles from "./Utilities/styles";
import { AccountCircle } from "@material-ui/icons";
import { NoAuthentication } from "./notAuthenticated";
import { NoAuthorization } from "./notAuthorized";

//Written by Harrison Norris and Sarah Swyer
export default function TabBar(props) {
	const [noAuthentication, setNoAuthentication] = React.useState(false);
	const [noAuthorization, setNoAuthorization] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [groups, setGroups] = React.useState([]);
	const [showInactive, setShowInactive] = React.useState(false);
	const [providers, setProviders] = React.useState([]);
	React.useEffect(() => {
		readGroups();
		readProviders();
	}, []);

	// Amplify API Call to GET providers-table endpoint, sets state of component with provider json object
	// Provider json data can be accessed via this.state.providers
	const readProviders = () => {
		Auth.currentAuthenticatedUser().then((response) => {
			const requestParams = {
				headers: {
					Authorization: response.signInUserSession.idToken.jwtToken,
				},
				body: {
					Groups: response.signInUserSession.idToken.payload["cognito:groups"],
				},
			};
			return API.get("providers", "/provider-table", requestParams)
				.then((response) => {
					if (response.statusCode === 200) {
						for (var i in response.body) {
							response.body[i].selected = false;
							response.body[i].show = true;
							response.body[i].filtered = true;
						}
						setProviders(response.body);
					}
				})
				.catch((error) => {
					setNoAuthentication(true);
				});
		});
	};

	//  Amplify API Call to GET groups-table endpoint, sets groups state
	const readGroups = () => {
		Auth.currentAuthenticatedUser().then((response) => {
			const requestParams = {
				headers: {
					Authorization: response.signInUserSession.idToken.jwtToken,
				},
			};
			API.get("providers", "/groups-table/", requestParams)
				.then((response) => {
					var tempGroupsList = [];
					var i;
					if (response.statusCode === 200) {
						for (i = 0; i < response.body.length; i++) {
							tempGroupsList[i] = {
								name: response.body[i].Name,
								active: false,
								count: response.body[i].Number,
							};
						}
						var sortedGroups = tempGroupsList.sort(function (a, b) {
							return a.name.localeCompare(b.name);
						});
						setGroups(sortedGroups);
					}
				})
				.catch((error) => {
					setNoAuthentication(true);
				});
		});
	};

	const getTab = () => {
		switch (page) {
			case 0:
				return (
					<Dashboard
						logout={props.logout}
						providers={providers}
						groups={groups}
						aria-label="Provider Statistics Dashboard"
					/>
				);
			case 1:
				return (
					<Database
						readProviders={readProviders}
						logout={props.logout}
						providers={providers}
						showInactive={showInactive}
						setProviders={setProviders}
						groups={groups}
						setGroups={setGroups}
						aria-label="Provider Database Table"
					/>
				);
			case 2:
				return (
					<Settings
						logout={props.logout}
						setShowInactive={() => setShowInactive(!showInactive)}
						showInactive={showInactive}
						groups={groups}
						setGroups={setGroups}
						aria-label="Manage user and CRM Settings"
					/>
				);
			default:
				return (
					<Dashboard
						logout={props.logout}
						providers={providers}
						groups={groups}
						aria-label="Provider Statistics Dashboard"
					/>
				);
		}
	};

	return (
		<>
			<ThemeProvider theme={styles.themeTabBar}>
				<AppBar elevation={0}>
					<Toolbar variant="dense" aria-label="Toolbar">
						<img id="logo" src="logo.png" alt="Logo" />
						<Tabs value={page}>
							<StyledTab
								label="Dashboard"
								onClick={() => setPage(0)}
								aria-label="Dashboard Tab"
							/>
							<StyledTab
								label="Database"
								onClick={() => setPage(1)}
								aria-label="Database Tab"
							/>
							<StyledTab
								label="Settings"
								onClick={() => setPage(2)}
								aria-label="Settings Tab"
							/>
						</Tabs>
						<div style={{ marginLeft: "auto" }} />
						{props.user !== null ? (
							<div id="account">
								<AccountCircle style={{ marginRight: 5 }} />
								{props.user.username}
							</div>
						) : null}
						<Button color="inherit" onClick={() => props.logout()}>
							Logout
						</Button>
					</Toolbar>
				</AppBar>
				{getTab()}
			</ThemeProvider>
			<NoAuthentication
				logout={props.logout}
				open={noAuthentication}
				onClose={() => setNoAuthentication(false)}
			/>
			<NoAuthorization
				open={noAuthorization}
				onClose={() => setNoAuthorization(false)}
			/>
		</>
	);
}
const StyledTab = withStyles((theme) => ({
	selected: {
		backgroundColor: "#455a64",
		color: "#FFFFFF",
		borderRadius: "15px 15px 0px 0px",
	},
}))(Tab);
