import React from "react";
import "./database.css";
import { ThemeProvider, Checkbox } from "@material-ui/core";
import { AutoSizer, MultiGrid } from "react-virtualized";
import styles from "../Utilities/styles";
import "react-virtualized/styles.css";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

//Written by Harrison Norris
export default function Table(props) {
	const [selected, setSelected] = React.useState(0);
	const [visibleProviders, setVisibleProviders] = React.useState([]);
	const [rows, setRows] = React.useState(0);

	//Update table attributes
	React.useEffect(() => {
		var selectedCount = 0;
		var rowCount = 1;
		var tempVisibleProviders = [];
		for (var i in props.providers) {
			if (props.providers[i].selected) {
				selectedCount++;
			}
			if (checkVisible(props.providers[i])) {
				tempVisibleProviders.push(props.providers[i]);
				rowCount++;
			}
		}
		setSelected(selectedCount);
		setRows(rowCount);
		setVisibleProviders(tempVisibleProviders);
	}, [props.providers]);

	//return whether a provider should be rendered
	const checkVisible = (provider) => {
		if (
			(provider.Active || props.showInactive) &&
			provider.show &&
			provider.filtered
		) {
			return true;
		} else {
			return false;
		}
	};

	//checks if head checkbox is indeterminate
	const checkIndeterminate = () => {
		if (selected > 0 && selected < rows - 1) {
			return true;
		} else {
			return false;
		}
	};

	//checks if all rows are selected
	const checkAllSelected = () => {
		if (selected > 0 && selected >= rows - 1) {
			return true;
		} else {
			return false;
		}
	};

	//selects all rows
	const selectAll = () => {
		var tempProviders = [...props.providers];

		if (checkAllSelected()) {
			for (var i in tempProviders) {
				tempProviders[i].selected = false;
			}
		} else {
			for (i in tempProviders) {
				if (checkVisible(tempProviders[i])) {
					tempProviders[i].selected = true;
				}
			}
		}
		props.setProviders(tempProviders);
	};

	//sort table based on new parameters
	const sortChange = (i) => {
		if (props.sortBy[i]) {
			props.setSortUp(!props.sortUp);
		} else {
			var newSortBy = [
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
			];
			newSortBy[i] = true;
			props.setSortBy(newSortBy);
		}
	};

	//returns column width as a percentage of screen width, with a minimum value
	const getColumnWidth = ({ index }) => {
		var width = window.innerWidth;
		switch (index) {
			default:
				return null;
			case 0:
				if (width * 0.024 < 50) {
					return 50;
				} else {
					return width * 0.024;
				}
			case 1:
				if (width * 0.024 < 72) {
					return 72;
				} else {
					return width * 0.024;
				}
			case 2:
				if (width * 0.053 < 130) {
					return 110;
				} else {
					return width * 0.053;
				}
			case 3:
				if (width * 0.053 < 130) {
					return 110;
				} else {
					return width * 0.053;
				}
			case 4:
				if (width * 0.068 < 140) {
					return 140;
				} else {
					return width * 0.068;
				}
			case 5:
				if (width * 0.11 < 300) {
					return 300;
				} else {
					return width * 0.11;
				}
			case 6:
				if (width * 0.06 < 200) {
					return 200;
				} else {
					return width * 0.06;
				}
			case 7:
				if (width * 0.1105 < 330) {
					return 330;
				} else {
					return width * 0.1105;
				}
			case 8:
				if (width * 0.073 < 150) {
					return 150;
				} else {
					return width * 0.073;
				}
			case 9:
				if (width * 0.044 < 90) {
					return 90;
				} else {
					return width * 0.044;
				}

			case 10:
				if (width * 0.044 < 100) {
					return 100;
				} else {
					return width * 0.044;
				}
			case 11:
				if (width * 0.058 < 120) {
					return 120;
				} else {
					return width * 0.058;
				}

			case 12:
				if (width * 0.058 < 120) {
					return 120;
				} else {
					return width * 0.058;
				}
			case 13:
				if (width * 0.136 < 350) {
					return 350;
				} else {
					return width * 0.136;
				}
		}
	};

	//renders each table cell based on x,y value
	const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
		if (rowIndex === 0) {
			var content = "";
			switch (columnIndex) {
				default:
					return null;
				case 0:
					return (
						<div key={key} style={style} className="tableHeadCheckbox">
							<Checkbox
								style={{ color: "white" }}
								indeterminate={checkIndeterminate()}
								checked={checkAllSelected()}
								onChange={() => selectAll()}
								aria-label="Checkbox"
							/>
						</div>
					);
				case 1:
					content = "Title";
					break;
				case 2:
					content = "First Name";
					break;
				case 3:
					content = "Last Name";
					break;
				case 4:
					content = "Provider Number";
					break;
				case 5:
					content = "Practice";
					break;
				case 6:
					content = "Position";
					break;
				case 7:
					content = "Address";
					break;
				case 8:
					content = "Suburb";
					break;
				case 9:
					content = "State";
					break;
				case 10:
					content = "Postcode";
					break;
				case 11:
					content = "Phone";
					break;
				case 12:
					content = "Fax";
					break;
				case 13:
					content = "Email";
					break;
			}
			var sort = null;
			if (props.sortBy[columnIndex - 1]) {
				if (props.sortUp) {
					sort = <ArrowDownward />;
				} else {
					sort = <ArrowUpward />;
				}
			}
			return (
				<div
					key={key}
					style={style}
					className="tableHead"
					onClick={() => sortChange(columnIndex - 1)}
					role="button"
				>
					{sort}
					{content}
				</div>
			);
		} else {
			var index = rowIndex - 1;
			switch (columnIndex) {
				default:
					return null;
				case 0:
					var className = "tableCellCheckbox";
					if (!visibleProviders[index].Active) {
						className = "tableCellCheckboxInactive";
					}
					if (visibleProviders[index].selected) {
						className = "tableCellCheckboxSelected";
					}
					return (
						<div key={key} style={style} className={className}>
							<Checkbox
								checked={visibleProviders[index].selected}
								onChange={() => {
									var tempProviders = [...visibleProviders];
									tempProviders[index].selected =
										!tempProviders[index].selected;
									props.setProviders(tempProviders);
								}}
							/>
						</div>
					);
				case 1:
					content = visibleProviders[index].Title;
					break;
				case 2:
					content = visibleProviders[index].FirstName;
					break;
				case 3:
					content = visibleProviders[index].LastName;
					break;
				case 4:
					content = visibleProviders[index].ProviderNumber;
					break;
				case 5:
					content = visibleProviders[index].Practice;
					break;
				case 6:
					content = visibleProviders[index].Position;
					break;
				case 7:
					content = visibleProviders[index].Street;
					break;
				case 8:
					content = visibleProviders[index].Suburb;
					break;
				case 9:
					content = visibleProviders[index].State;
					break;
				case 10:
					content = visibleProviders[index].PostCode;
					break;
				case 11:
					content = visibleProviders[index].Phone;
					break;
				case 12:
					content = visibleProviders[index].Fax;
					break;
				case 13:
					content = visibleProviders[index].Email;
					break;
			}
			className = "tableCell";
			if (!visibleProviders[index].Active) {
				className = "tableCellInactive";
			}
			if (visibleProviders[index].selected) {
				className = "tableCellSelected";
			}
			return (
				<div
					key={key}
					style={style}
					className={className}
					onClick={() => props.openEntryView(visibleProviders[index])}
				>
					{content}
				</div>
			);
		}
	};
	return (
		<ThemeProvider theme={styles.themeDefault}>
			<div id="container">
				<AutoSizer>
					{({ height, width }) => (
						<MultiGrid
							width={width}
							height={height}
							columnCount={14}
							columnWidth={getColumnWidth}
							rowCount={rows}
							rowHeight={60}
							overscanColumnCount={2}
							overscanRowCount={10}
							cellRenderer={cellRenderer}
							fixedRowCount={1}
						/>
					)}
				</AutoSizer>
			</div>
		</ThemeProvider>
	);
}
