export default class DuplicateChecker {
	static isBlank(str) {
		if (str === undefined) {
			return true;
		} else if (str === null) {
			return true;
		} else if (str === "") {
			return true;
		} else {
			return false;
		}
	}

	static checkDuplicates(newProvider, providers) {
		if (!DuplicateChecker.isBlank(newProvider.ProviderNumber)) {
			var pNumCheck = providers.filter(
				(provider) => provider.ProviderNumber === newProvider.ProviderNumber
			);
			if (pNumCheck.length > 0) {
				return pNumCheck[0];
			}
		}

		if (
			!DuplicateChecker.isBlank(newProvider.FirstName) &&
			!DuplicateChecker.isBlank(newProvider.LastName) &&
			!DuplicateChecker.isBlank(newProvider.Street)
		) {
			var nameAndAddressCheck = providers.filter(
				(provider) =>
					provider.FirstName === newProvider.FirstName &&
					provider.LastName === newProvider.LastName &&
					provider.Street === newProvider.Street
			);
			if (nameAndAddressCheck.length > 0) {
				return nameAndAddressCheck[0];
			}
		}

		if (
			!DuplicateChecker.isBlank(newProvider.FirstName) &&
			!DuplicateChecker.isBlank(newProvider.LastName) &&
			!DuplicateChecker.isBlank(newProvider.Phone)
		) {
			var nameAndNumberCheck = providers.filter(
				(provider) =>
					provider.FirstName === newProvider.FirstName &&
					provider.LastName === newProvider.LastName &&
					provider.Phone === newProvider.Phone
			);
			if (nameAndNumberCheck.length > 0) {
				return nameAndNumberCheck[0];
			}
		}

		if (
			!DuplicateChecker.isBlank(newProvider.FirstName) &&
			!DuplicateChecker.isBlank(newProvider.LastName) &&
			!DuplicateChecker.isBlank(newProvider.Email)
		) {
			var nameAndEmailCheck = providers.filter(
				(provider) =>
					provider.FirstName === newProvider.FirstName &&
					provider.LastName === newProvider.LastName &&
					provider.Email === newProvider.Email
			);
			if (nameAndEmailCheck.length > 0) {
				return nameAndEmailCheck[0];
			}
		}

		var exactCheck = providers.filter(
			(provider) =>
				provider.Title === newProvider.Title &&
				provider.FirstName === newProvider.FirstName &&
				provider.LastName === newProvider.LastName &&
				provider.ProviderNumber === newProvider.ProviderNumber &&
				provider.Practice === newProvider.Practice &&
				provider.Position === newProvider.Position &&
				provider.Street === newProvider.Street &&
				provider.Suburb === newProvider.Suburb &&
				provider.State === newProvider.State &&
				provider.PostCode === newProvider.PostCode &&
				provider.Phone === newProvider.Phone &&
				provider.Fax === newProvider.Fax &&
				provider.Email === newProvider.Email
		);
		if (exactCheck.length > 0) {
			return exactCheck[0];
		}

		return null;
	}

	static updateDuplicateRecord(
		newProvider,
		duplicateProvider,
		updateReferralCount,
		newGroups
	) {
		var newReferralCount = duplicateProvider.Referrals;
		if (updateReferralCount) {
			newReferralCount++;
		}

		var updatedGroups = [...duplicateProvider.Group];
		for (var i in newGroups) {
			var duplicate = false;
			for (var j in duplicateProvider.Group) {
				if (newGroups[i] === duplicateProvider.Group[j]) {
					duplicate = true;
				}
			}
			if (!duplicate) {
				updatedGroups.push(newGroups[i]);
			}
		}

		const updatedAttributes = {
			ID: duplicateProvider.ID,
			Parameter: ["Referrals", "Group"],
			Value: [newReferralCount, updatedGroups],
		};

		for (var key in newProvider) {
			if (
				key !== "ID" &&
				newProvider[key] !== "" &&
				key !== "Referrals" &&
				key !== "Display" &&
				key !== "Group"
			) {
				updatedAttributes.Parameter.push(key);
				updatedAttributes.Value.push(newProvider[key]);
			}
		}
		return updatedAttributes;
	}
}
