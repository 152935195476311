import React from "react";
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	DialogActions,
	ThemeProvider,
	ListItem,
	ListItemIcon,
	Checkbox,
	ListItemText,
	Divider,
} from "@material-ui/core";
import styles from "../../Utilities/styles";
import CSVReader from "react-csv-reader";
import FaxFormatter from "../../Utilities/faxFormatter.js";
import Validate from "../../Utilities/validate";
import DuplicateChecker from "../../Utilities/duplicateManager";
import { API, Auth } from "aws-amplify";
import { NoAuthentication } from "../../notAuthenticated";
import { NoAuthorization } from "../../notAuthorized";

//Written by Harrison Norris and Sarah Swyer
export const ImportRecords = React.memo(
	(props) => {
		const [noAuthentication, setNoAuthentication] = React.useState(false);
		const [noAuthorization, setNoAuthorization] = React.useState(false);
		const [selectFile, setSelectFile] = React.useState(true);
		const [updateReferrals, setUpdateReferrals] = React.useState(true);
		const [groups, setGroups] = React.useState([]);
		const [filePicked, setFilePicked] = React.useState(false);
		const [file, setFile] = React.useState(null);
		const [previewOpen, setPreviewOpen] = React.useState(false);
		const [toImport, setToImport] = React.useState([]);
		const [toUpdate, setToUpdate] = React.useState([]);
		const [results, setResults] = React.useState({
			new: 0,
			duplicates: 0,
			failed: 0,
			testsFailed: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		});

		//reset state
		React.useEffect(() => {
			var tempGroups = [...props.groups];
			for (var i in tempGroups) {
				tempGroups[i].active = false;
			}
			setResults({
				new: 0,
				duplicates: 0,
				failed: 0,
				testsFailed: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			});
			setGroups(tempGroups);
			setUpdateReferrals(true);
			setSelectFile(true);
			setFilePicked(false);
			setPreviewOpen(false);
		}, [props.open]);

		//returns select file ui component
		const getSelectFile = () => {
			return (
				<CSVReader
					cssClass="csv-reader-input"
					onFileLoaded={(data) => {
						setFile(data);
						setFilePicked(true);
					}}
					parserOptions={{
						header: true,
						skipEmptyLines: true,
						transformHeader: (header) =>
							header.toLowerCase().replace(/\W/g, ""),
					}}
				/>
			);
		};

		//Validate providers and prepare for import
		const importProviders = () => {
			var providerGroups = [];
			for (var i in groups) {
				if (groups[i].active) {
					providerGroups.push(groups[i].name);
				}
			}
			var referrals = 0;
			if (updateReferrals) {
				referrals = 1;
			}
			setPreviewOpen(true);
			var importArray = [];
			var updateArray = [];
			for (var i in file) {
				var tempResults = results;
				var provider = generateProviderJSON(file[i]);
				provider.Referrals = referrals;
				provider.Group = providerGroups;
				if (validate(provider)) {
					var duplicateIndex = DuplicateChecker.checkDuplicates(provider, [
						...props.providers,
						...importArray,
					]);
					if (duplicateIndex === null) {
						//create
						importArray.push(provider);
						tempResults.new++;
					} else {
						var updatedProvider = DuplicateChecker.updateDuplicateRecord(
							provider,
							duplicateIndex,
							updateReferrals,
							providerGroups
						);
						//update
						updateArray.push(updatedProvider);
						tempResults.duplicates++;
					}
				} else {
					tempResults.failed++;
				}

				setResults(tempResults);
			}
			setToImport(importArray);
			setToUpdate(updateArray);
		};

		//Send data to backend
		const confirmImport = () => {
			props.onClose();
			for (let i in toImport) {
				createProvider(toImport[i]);
			}
			for (let i in toUpdate) {
				updateProvider(toUpdate[i]);
			}
			props.readProviders();
		};

		//API call to create provider
		const createProvider = (provider) => {
			Auth.currentAuthenticatedUser().then((response) => {
				var Groups = [];
				if (
					response.signInUserSession.idToken.payload["cognito:groups"] !==
					undefined
				) {
					Groups = response.signInUserSession.idToken.payload["cognito:groups"];
				}
				const requestParams = {
					headers: {
						Authorization: response.signInUserSession.idToken.jwtToken,
					},
					body: {
						User: response.signInUserSession.idToken.payload[
							"cognito:username"
						],
						Groups,
						provider,
					},
				};
				return API.post("providers", "/provider-table/provider", requestParams)
					.then((response) => {
						if (response.statusCode === 200) {
						} else if (response.statusCode === 403) {
							setNoAuthorization(true);
						}
					})
					.catch((error) => {
						setNoAuthentication(true);
					});
			});
		};

		//API call to update provider
		const updateProvider = (updatedAttributes) => {
			const ID = updatedAttributes.ID;
			const Parameter = updatedAttributes.Parameter;
			const Value = updatedAttributes.Value;
			Auth.currentAuthenticatedUser().then((response) => {
				const requestParams = {
					headers: {
						// Authorization: response.signInUserSession.idToken.jwtToken,
						Authorization: response.signInUserSession.idToken.jwtToken,
					},
					body: {
						User: response.signInUserSession.idToken.payload[
							"cognito:username"
						],
						Groups:
							response.signInUserSession.idToken.payload["cognito:groups"],
						ID,
						Parameter,
						Value,
					},
				};
				API.patch("providers", "/provider-table/provider", requestParams)
					.then((response) => {
						if (response.statusCode === 200) {
							if (response.body !== "Nothing was updated") {
								setProvider(response.body);
							}
						} else if (response.statusCode === 403) {
							setNoAuthorization(true);
						}
					})
					.catch((error) => {
						setNoAuthentication(true);
					});
			});
		};

		//update provider for UI
		const setProvider = (provider) => {
			var tempProviders = [...props.providers];
			for (var i in tempProviders) {
				if (provider.ID === tempProviders[i].ID) {
					tempProviders[i] = provider;
					tempProviders[i].selected = false;
					tempProviders[i].show = true;
					tempProviders[i].filtered = true;
				}
			}

			props.setProviders(tempProviders);
		};

		//Validate provider fields
		const validate = (provider) => {
			var failedTest = [...results.testsFailed];
			var failed = false;
			if (!Validate.providerNumber(provider.ProviderNumber)) {
				failedTest[3]++;
				console.log("Failed pNum");
				failed = true;
			}
			if (!Validate.email(provider.Email)) {
				failedTest[4]++;
				console.log("Failed email");
				failed = true;
			}
			if (!Validate.suburb(provider.Suburb)) {
				failedTest[8]++;
				console.log("Failed Suburb");
				failed = true;
			}
			if (!Validate.state(provider.State)) {
				failedTest[9]++;
				console.log("Failed State");
				failed = true;
			}
			if (!Validate.postcode(provider.PostCode)) {
				failedTest[10]++;
				console.log("Failed Postcode");
				failed = true;
			}
			if (!Validate.phone(provider.Phone)) {
				failedTest[11]++;
				console.log("Failed Phone");
				failed = true;
			}
			if (!Validate.fax(provider.Fax)) {
				failedTest[12]++;
				console.log("Failed Fax");
				failed = true;
			}
			var tempResults = results;
			tempResults.testsFailed = failedTest;
			setResults(tempResults);
			if (
				(provider.Title === undefined || provider.Title.length === 0) &&
				(provider.FirstName === undefined || provider.FirstName.length === 0) &&
				(provider.LastName === undefined || provider.LastName.length === 0) &&
				(provider.ProviderNumber === undefined ||
					provider.ProviderNumber.length === 0) &&
				(provider.Practice === undefined || provider.Practice.length === 0) &&
				(provider.Position === undefined || provider.Position.length === 0) &&
				(provider.Street === undefined || provider.Street.length === 0) &&
				(provider.Suburb === undefined || provider.Suburb.length === 0) &&
				(provider.State === undefined || provider.State.length === 0) &&
				(provider.PostCode === undefined || provider.PostCode.length === 0) &&
				(provider.Phone === undefined || provider.Phone.length === 0) &&
				(provider.Fax === undefined || provider.Fax.length === 0) &&
				(provider.Email === undefined || provider.Email.length === 0)
			) {
				failed = true;
			}
			return !failed;
		};

		//Format and return a JSON object
		const generateProviderJSON = (provider) => {
			return {
				ProviderNumber:
					provider.providernumber === undefined
						? ""
						: provider.providernumber.trim(),
				Title:
					provider.title === undefined
						? ""
						: Validate.toTitleCase(provider.title),
				FirstName:
					provider.firstname === undefined
						? ""
						: Validate.toTitleCase(provider.firstname),
				LastName:
					provider.lastname === undefined
						? ""
						: Validate.toTitleCase(provider.lastname),
				Practice:
					provider.practice === undefined
						? ""
						: Validate.toTitleCase(provider.practice),
				Position:
					provider.position === undefined
						? ""
						: Validate.toTitleCase(provider.position),
				Street:
					provider.street === undefined
						? ""
						: Validate.toTitleCase(provider.street),
				Suburb:
					provider.suburb === undefined
						? ""
						: Validate.toTitleCase(provider.suburb),
				State: provider.state === undefined ? "" : provider.state.toUpperCase(),
				PostCode:
					provider.postcode === undefined ? "" : provider.postcode.trim(),
				Email: provider.email === undefined ? "" : provider.email.trim(),
				Phone: provider.phone === undefined ? "" : provider.phone.trim(),
				Fax:
					provider.fax === undefined ? "" : FaxFormatter(provider.fax.trim()),
				Group: [],
				Active: true,
				Referrals: 1,
			};
		};

		//Select a group
		const setSelected = (i) => {
			var tempGroups = [...groups];
			tempGroups[i].active = !tempGroups[i].active;
			setGroups(tempGroups);
		};

		//Return group list UI component
		const getGroups = () => {
			return (
				<div style={{ width: 320 }}>
					<Divider />
					<div style={{ maxHeight: 320, overflow: "auto" }}>
						{groups.map((value, i) => {
							return (
								<ListItem
									key={value.name}
									dense
									button
									onClick={() => setSelected(i)}
								>
									<ListItemIcon>
										<Checkbox edge="start" checked={value.active} />
									</ListItemIcon>
									<ListItemText primary={value.name} />
								</ListItem>
							);
						})}
					</div>
					<Divider />
					<ListItem button onClick={() => setUpdateReferrals(!updateReferrals)}>
						<ListItemIcon>
							<Checkbox edge="start" checked={updateReferrals} />
						</ListItemIcon>
						<ListItemText primary="Update referral count" />
					</ListItem>
				</div>
			);
		};

		//Return failed statistics UI component
		const getFailedStats = () => {
			var failedStats = "";

			if (results.testsFailed[4] > 0) {
				failedStats += results.testsFailed[4] + " failed Email validation\n";
			}
			if (results.testsFailed[8] > 0) {
				failedStats += results.testsFailed[8] + " failed Suburb validation\n";
			}
			if (results.testsFailed[9] > 0) {
				failedStats += results.testsFailed[9] + " failed State validation\n";
			}
			if (results.testsFailed[10] > 0) {
				failedStats +=
					results.testsFailed[10] + " failed Postcode validation\n";
			}
			if (results.testsFailed[11] > 0) {
				failedStats += results.testsFailed[11] + " failed Phone validation\n";
			}
			if (results.testsFailed[12] > 0) {
				failedStats += results.testsFailed[4] + " failed Fax validation\n";
			}
			return (
				<div>
					{results.testsFailed[3] > 0
						? results.testsFailed[3] + " failed ProviderNumber validation\n"
						: null}
					{results.testsFailed[3] > 0 ? <br /> : null}
					{results.testsFailed[4] > 0
						? results.testsFailed[4] + " failed Email validation\n"
						: null}
					{results.testsFailed[4] > 0 ? <br /> : null}
					{results.testsFailed[8] > 0
						? results.testsFailed[8] + " failed Suburb validation\n"
						: null}
					{results.testsFailed[8] > 0 ? <br /> : null}
					{results.testsFailed[9] > 0
						? results.testsFailed[9] + " failed State validation\n"
						: null}
					{results.testsFailed[9] > 0 ? <br /> : null}
					{results.testsFailed[10] > 0
						? results.testsFailed[10] + " failed Postcode validation\n"
						: null}
					{results.testsFailed[10] > 0 ? <br /> : null}
					{results.testsFailed[11] > 0
						? results.testsFailed[11] + " failed Phone validation\n"
						: null}
					{results.testsFailed[11] > 0 ? <br /> : null}
					{results.testsFailed[12] > 0
						? results.testsFailed[12] + " failed Fax validation\n"
						: null}
					{results.testsFailed[12] > 0 ? <br /> : null}
				</div>
			);
		};

		return (
			<ThemeProvider theme={styles.themeDefault}>
				<Dialog
					open={props.open && !previewOpen}
					onClose={() => props.onClose()}
				>
					<DialogTitle>Import From .CSV</DialogTitle>
					<DialogContent>
						{selectFile ? getSelectFile() : getGroups()}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => props.onClose()}>Cancel</Button>
						<Button
							variant="contained"
							color="primary"
							disabled={!filePicked}
							onClick={() => {
								if (selectFile) {
									setSelectFile(false);
								} else {
									importProviders();
								}
							}}
						>
							{selectFile ? "Next" : "Preview"}
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog open={previewOpen} onClose={() => setPreviewOpen(false)}>
					<DialogTitle>Import Records - Results</DialogTitle>
					<DialogContent>
						New Records: {results.new}
						<br />
						Duplicate Records: {results.duplicates}
						<br />
						Failed Validation: {results.failed}
						<br />
						{getFailedStats()}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								props.onClose();
								setToImport([]);
								setToUpdate([]);
							}}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => confirmImport()}
						>
							Import
						</Button>
					</DialogActions>
				</Dialog>
				<NoAuthentication
					logout={props.logout}
					open={noAuthentication}
					onClose={() => setNoAuthentication(false)}
				/>
				<NoAuthorization
					open={noAuthorization}
					onClose={() => setNoAuthorization(false)}
				/>
			</ThemeProvider>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
