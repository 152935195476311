import React from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Snackbar,
	IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Auth } from "aws-amplify";
import Validate from "../../Utilities/validate";

//Written by Harrison Norris
export const ForgotPassword = React.memo(
	(props) => {
		const [resetPasswordCode, setResetPasswordCode] = React.useState(false);
		const [usernameValidated, setUsernameValidated] = React.useState(false);
		const [username, setUsername] = React.useState(null);
		const [errors, setErrors] = React.useState({
			code: false,
			password: false,
			confirmPassword: false,
		});
		const [passwordResetSnackbar, setPasswordResetSnackbar] =
			React.useState(false);
		//Validates username
		const checkUsername = () => {
			if (
				document.getElementById("forgotPasswordUsername").value.length === 0
			) {
				setUsernameValidated(false);

				//TODO verify against list of actual usernames
			} else {
				setUsernameValidated(true);
			}
		};
		//Sends a verification code to the user given a username
		const sendCode = () => {
			var _username;
			if (!resetPasswordCode) {
				_username = document.getElementById("forgotPasswordUsername").value;
				setUsername(_username);
				setResetPasswordCode(true);
			}
			setErrors({ code: false, confirmPassword: false, password: false });
			if (!resetPasswordCode) {
				Auth.forgotPassword(_username);
			} else {
				Auth.forgotPassword(username);
			}
		};
		//Checks for empty fields and makes sure password and confirm password work
		const validateFields = () => {
			var newErrors = errors;
			if (
				document.getElementById("forgotPasswordPassword").value !==
				document.getElementById("forgotPasswordConfirm").value
			) {
				newErrors.confirmPassword = true;
			} else {
				newErrors.confirmPassword = false;
			}
			if (
				!Validate.resetCode(document.getElementById("forgotPasswordCode").value)
			) {
				newErrors.code = true;
			} else {
				newErrors.code = false;
			}
			if (
				!Validate.password(
					document.getElementById("forgotPasswordPassword").value
				)
			) {
				newErrors.password = true;
			} else {
				newErrors.password = false;
			}
			//reset the error array to have no errors
			setErrors({
				code: newErrors.code,
				password: newErrors.password,
				confirmPassword: newErrors.confirmPassword,
			});
			if (newErrors.confirmPassword || newErrors.code || newErrors.password) {
				return false;
			} else {
				return true;
			}
		};
		//Resets password with code and new password fields
		//TODO add error handling (incorrect code)
		const resetPassword = () => {
			Auth.forgotPasswordSubmit(
				username,
				document.getElementById("forgotPasswordCode").value,
				document.getElementById("forgotPasswordPassword").value
			)
				.then((data) => {
					//reset state, open snackbar
					setPasswordResetSnackbar(true);

					props.onClose(false);
					setResetPasswordCode(false);
					setUsernameValidated(false);
					setUsername(null);
					setErrors({
						code: false,
						password: false,
						confirmPassword: false,
					});
				})
				.catch((err) =>
					setErrors({ code: true, password: false, confirmPassword: false })
				);
		};
		return (
			<>
				<Dialog
					open={props.open}
					onClose={() => {
						props.onClose(false);
						setResetPasswordCode(false);
						setUsernameValidated(false);
						setUsername(null);
						setErrors({
							code: false,
							password: false,
							confirmPassword: false,
						});
					}}
				>
					<DialogTitle>
						{resetPasswordCode
							? "Reset Password - " + username
							: "Forgot Password"}
					</DialogTitle>
					<DialogContent style={{ maxWidth: 350 }}>
						{resetPasswordCode ? (
							<>
								<Button fullWidth onClick={() => sendCode()}>
									Send code again
								</Button>
								<br />
								<TextField
									fullWidth
									label="Code"
									id="forgotPasswordCode"
									onChange={() => {
										if (errors.code) {
											validateFields();
										}
									}}
									helperText={errors.code ? "Invalid code" : null}
									error={errors.code}
								/>
								<br />
								<TextField
									fullWidth
									label="New Password"
									type="password"
									id="forgotPasswordPassword"
									onChange={() => {
										if (errors.password) {
											validateFields();
										}
									}}
									helperText={
										errors.password
											? "Password must be at least 8 characters and contain a mix of uppercase and lowecase letters, numbers and symbols"
											: null
									}
									error={errors.password}
								/>
								<br />
								<TextField
									fullWidth
									label="Confirm New Password"
									type="password"
									id="forgotPasswordConfirm"
									onChange={() => {
										if (errors.confirmPassword) {
											validateFields();
										}
									}}
									error={errors.confirmPassword}
									helperText={
										errors.confirmPassword ? "Passwords don't match" : null
									}
								/>
								<br />
							</>
						) : (
							<>
								<TextField
									fullWidth
									label="Username"
									id="forgotPasswordUsername"
									onChange={() => checkUsername()}
								/>

								<Button
									fullWidth
									onClick={() => sendCode()}
									disabled={!usernameValidated}
								>
									Send code
								</Button>
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								props.onClose(false);
								setResetPasswordCode(false);
								setUsernameValidated(false);
								setUsername(null);
								setErrors({
									code: false,
									password: false,
									confirmPassword: false,
								});
							}}
						>
							Cancel
						</Button>
						{resetPasswordCode ? (
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									if (validateFields()) {
										resetPassword();
									}
								}}
							>
								Reset
							</Button>
						) : null}
					</DialogActions>
				</Dialog>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={passwordResetSnackbar}
					autoHideDuration={2000}
					onClose={() => setPasswordResetSnackbar(false)}
					message="Password has been reset"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setPasswordResetSnackbar(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
			</>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
