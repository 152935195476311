import React from "react";
import {
	Dialog,
	DialogTitle,
	Button,
	DialogContent,
	DialogActions,
	ThemeProvider,
	OutlinedInput,
	Snackbar,
	IconButton,
} from "@material-ui/core";
import styles from "../../Utilities/styles";
import { Close } from "@material-ui/icons";

//Written by Harrison Norris
export const Fax = React.memo(
	(props) => {
		const [confirmOpen, setConfirmOpen] = React.useState(false);
		const [faxes, setFaxes] = React.useState(null);

		//Get fax numbers from selected providers
		React.useEffect(() => {
			var fax = "";

			for (var i in props.selected) {
				if (
					!fax.includes(props.selected[i].Fax) &&
					props.selected[i].Fax !== null &&
					props.selected[i].Fax !== undefined
				) {
					fax = fax + props.selected[i].Fax + ", ";
				}
			}

			setFaxes(fax);
		}, [props.selected]);

		//Copy faxes to clipboard
		const copy = (fax) => {
			navigator.clipboard.writeText(fax);
			props.onClose();
			setConfirmOpen(true);
		};

		return (
			<>
				<ThemeProvider theme={styles.themeDefault}>
					<Dialog open={props.open} onClose={() => props.onClose()}>
						<DialogTitle>Fax Selection</DialogTitle>
						<DialogContent>
							<div style={{ width: 500 }}>
								<OutlinedInput
									multiline
									rows={6}
									defaultValue={faxes}
									variant="outlined"
									fullWidth
									readOnly
								/>
							</div>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => props.onClose()}>Cancel</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={() => copy(faxes)}
							>
								Copy to Clipboard
							</Button>
						</DialogActions>
					</Dialog>
				</ThemeProvider>

				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={confirmOpen}
					autoHideDuration={2000}
					onClose={() => setConfirmOpen(false)}
					message="Record(s) copied to clipboard"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setConfirmOpen(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
			</>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
