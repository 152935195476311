import React from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Snackbar,
	IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Auth } from "aws-amplify";
import Validate from "../../Utilities/validate";

//Written by Harrison Norris
export const CreateUser = React.memo(
	(props) => {
		const [errors, setErrors] = React.useState({
			username: false,
			email: false,
			password: false,
			confirmPassword: false,
		});
		const [newUser, setNewUser] = React.useState(null);
		const [confirmNewUser, setConfirmNewUser] = React.useState(false);
		const [userAddedSnackbar, setUserAddedSnackbar] = React.useState(false);

		//Adds a new user given username, email and password
		const addNewUser = () => {
			var username = newUser.username;
			var password = newUser.password;
			var email = newUser.email;
			Auth.signUp({ username, password, attributes: { email } })
				.then((user) => {
					props.onClose(false);
					setConfirmNewUser(false);
					setErrors({
						username: false,
						email: false,
						password: false,
						confirmPassword: false,
					});
					setNewUser(null);
					setUserAddedSnackbar(true);
				})
				.catch((error) => {
					console.log(error);
				});
		};
		//Checks for empty fields and makes sure password and confirm password work
		const validateNewUserFields = () => {
			var newErrors = errors;
			if (
				document.getElementById("password").value !==
				document.getElementById("confirmPassword").value
			) {
				newErrors.confirmPassword = true;
			} else {
				newErrors.confirmPassword = false;
			}
			if (!Validate.email(document.getElementById("email").value)) {
				newErrors.email = true;
			} else {
				newErrors.email = false;
			}
			if (!Validate.password(document.getElementById("password").value)) {
				newErrors.password = true;
			} else {
				newErrors.password = false;
			}
			if (!Validate.username(document.getElementById("username").value)) {
				newErrors.username = true;
			} else {
				newErrors.username = false;
			}
			//reset the error array to have no errors
			setErrors({
				username: newErrors.username,
				email: newErrors.email,
				password: newErrors.password,
				confirmPassword: newErrors.confirmPassword,
			});
			if (
				newErrors.confirmPassword ||
				newErrors.email ||
				newErrors.password ||
				newErrors.username
			) {
				return false;
			} else {
				return true;
			}
		};

		return (
			<>
				<Dialog
					open={props.open}
					onClose={() => {
						props.onClose(false);
						setConfirmNewUser(false);
						setErrors({
							username: false,
							email: false,
							password: false,
							confirmPassword: false,
						});
						setNewUser(null);
					}}
				>
					<DialogTitle>Create New User</DialogTitle>
					<DialogContent>
						{confirmNewUser ? (
							"Are you sure you want to create user " + newUser.username
						) : (
							<>
								<TextField
									label="User Name"
									id="username"
									onChange={() => {
										if (errors.username) validateNewUserFields();
									}}
									style={{ width: 200 }}
									error={errors.username}
								/>
								<br />
								<TextField
									label="Email"
									id="email"
									type="email"
									onChange={() => {
										if (errors.email) validateNewUserFields();
									}}
									style={{ width: 200 }}
									error={errors.email}
									helperText={errors.email ? "Email is not valid" : null}
								/>
								<br />
								<TextField
									label="Password"
									id="password"
									type="password"
									autoComplete="new-password"
									onChange={() => {
										if (errors.password) validateNewUserFields();
									}}
									style={{ width: 200 }}
									error={errors.password}
									helperText={
										errors.password
											? "Password must contain a mix of uppercase and lowecase letters, numbers and symbols"
											: null
									}
								/>
								<br />
								<TextField
									autoComplete="new-password"
									label="Confirm Password"
									id="confirmPassword"
									type="password"
									error={errors.confirmPassword}
									style={{ width: 200 }}
									helperText={
										errors.confirmPassword ? "Passwords don't match" : null
									}
									onChange={() => {
										if (errors.confirmPassword) validateNewUserFields();
									}}
								/>
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => {
								props.onClose(false);
								setConfirmNewUser(false);
								setErrors({
									username: false,
									email: false,
									password: false,
									confirmPassword: false,
								});
								setNewUser(null);
							}}
						>
							Cancel
						</Button>
						{confirmNewUser ? (
							<Button
								variant="contained"
								color="primary"
								onClick={() => addNewUser()}
							>
								Confirm
							</Button>
						) : (
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									if (validateNewUserFields()) {
										setNewUser({
											username: document.getElementById("username").value,
											email: document.getElementById("email").value,
											password: document.getElementById("password").value,
										});
										setConfirmNewUser(true);
									}
								}}
							>
								Save
							</Button>
						)}
					</DialogActions>
				</Dialog>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={userAddedSnackbar}
					autoHideDuration={2000}
					onClose={() => setUserAddedSnackbar(false)}
					message="User added"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setUserAddedSnackbar(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
			</>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
