import React from "react";
import "./entryView.css";
import {
	TextField,
	Dialog,
	DialogContent,
	ListItem,
	ListItemIcon,
	ListItemText,
	Checkbox,
	Grid,
	Button,
	DialogActions,
	DialogTitle,
	OutlinedInput,
	ThemeProvider,
} from "@material-ui/core";
import { API, Auth } from "aws-amplify";
import styles from "../../Utilities/styles";
import EditProvider from "./editProvider";
import { NoAuthentication } from "../../notAuthenticated";
import { NoAuthorization } from "../../notAuthorized";

//Written by Harrison Norris and Sarah Swyer
export default function EntryView(props) {
	const [noAuthentication, setNoAuthentication] = React.useState(false);
	const [noAuthorization, setNoAuthorization] = React.useState(false);
	const [showHistory, setShowHistory] = React.useState(false);
	const [deleteClient, setDeleteClient] = React.useState(false);
	const [confirmInactive, setConfirmInactive] = React.useState(false);
	const [checkedGroups, setCheckedGroups] = React.useState([]);
	const [editOpen, setEditOpen] = React.useState(false);
	const [userIsAdmin, setUserIsAdmin] = React.useState(false);
	const [userIsReadWrite, setUserIsReadWrite] = React.useState(false);
	const [history, setHistory] = React.useState({
		RecordHistory: null,
		VisitHistory: null,
		MerchHistory: null,
		CommsHistory: null,
	});
	var updatedProvider = [];
	var updatedHistory = [];

	//check permissions
	React.useEffect(() => {
		Auth.currentAuthenticatedUser().then((user) => {
			if (
				user.signInUserSession.idToken.payload["cognito:groups"] !==
					undefined &&
				user.signInUserSession.idToken.payload["cognito:groups"][0] ===
					"read/write"
			) {
				setUserIsReadWrite(true);
			} else {
				setUserIsReadWrite(false);
			}
			if (
				user.signInUserSession.idToken.payload["cognito:groups"] !==
					undefined &&
				user.signInUserSession.idToken.payload["cognito:groups"][0] === "admin"
			) {
				setUserIsAdmin(true);
				setUserIsReadWrite(true);
			} else {
				setUserIsAdmin(false);
			}
		});
	}, []);

	//prepares entryview for new provider
	React.useEffect(() => {
		if (props.provider !== null) {
			setHistory({
				RecordHistory: null,
				VisitHistory: null,
				MerchHistory: null,
				CommsHistory: null,
			});
			readHistory(props.provider.ID);
			setCheckedGroups(props.provider.Group);
		}
	}, [props.provider]);

	//gets textfields data
	const readHistory = (id) => {
		Auth.currentAuthenticatedUser().then((response) => {
			const requestParams = {
				headers: {
					Authorization: response.signInUserSession.idToken.jwtToken,
				},
				body: {
					Groups: response.signInUserSession.idToken.payload["cognito:groups"],
				},
			};
			API.get("providers", "/history-table/" + id, requestParams)
				.then((response) => {
					if (response.statusCode === 200) {
						setHistory(response.body);
					}
				})
				.catch((error) => {
					setNoAuthentication(true);
				});
		});
	};

	//decifers record history
	const printRecordHistory = () => {
		var tenRecords = "";
		var records = history.RecordHistory;
		if (records !== undefined && records !== null) {
			for (var i = records.length - 1; i > records.length - 11; i--) {
				if (records[i] !== undefined) {
					tenRecords = tenRecords + records[i] + "\n\n";
				}
			}
			return tenRecords;
		}
	};

	//Returns active value of current provider
	const checkActive = () => {
		if (props.provider != null) {
			if (props.provider.Active) {
				return true;
			}
		} else {
			return false;
		}
	};

	//checks if provider belongs to a group
	const checkGroup = (value) => {
		if (props.provider !== null && checkedGroups !== undefined) {
			return checkedGroups.includes(value.name);
		} else {
			return false;
		}
	};

	//adds/removes group from provider
	const handleGroupCheck = (name) => {
		var tempCheckedGroups = [...checkedGroups];
		if (tempCheckedGroups.includes(name)) {
			tempCheckedGroups.splice(tempCheckedGroups.indexOf(name), 1);
		} else {
			tempCheckedGroups.push(name);
		}
		tempCheckedGroups.sort();
		setCheckedGroups(tempCheckedGroups);
	};

	//gets provider info (business card)
	const getInfo = () => {
		if (props.provider !== null) {
			return (
				<td id="info">
					<h2>
						{props.provider.Title +
							" " +
							props.provider.FirstName +
							" " +
							props.provider.LastName}
						{checkActive() ? "" : " (INACTIVE)"}
					</h2>
					{props.provider.ProviderNumber}
					<br />
					{props.provider.Practice}
					<br />

					{props.provider.Street}
					<br />
					{props.provider.Suburb +
						" " +
						props.provider.State +
						" " +
						props.provider.PostCode}
					<br />
					{"Phone: "}
					{props.provider.Phone}
					<br />
					{"Fax: "}
					{props.provider.Fax}
					<br />
					{props.provider.Email}
					<br />
					{"Referrals: "}
					{props.provider.Referrals}
				</td>
			);
		}
	};

	//gets record history
	const getHistory = () => {
		return (
			<>
				<h4>Record History</h4>
				<OutlinedInput
					autoComplete="new-password"
					id="recordHistory"
					multiline
					rows={26}
					readOnly
					fullWidth
					variant="outlined"
					defaultValue={printRecordHistory()}
				></OutlinedInput>
			</>
		);
	};

	//gets 3 textfields
	const getReports = () => {
		return (
			<>
				<h4>Visit History</h4>
				<TextField
					autoComplete="new-password"
					multiline
					rows={6}
					fullWidth
					variant="outlined"
					defaultValue={history.VisitHistory}
					onKeyUp={(e) => (updatedHistory["VisitHistory"] = e.target.value)}
				></TextField>
				<h4>Merch History</h4>
				<TextField
					autoComplete="new-password"
					multiline
					rows={6}
					fullWidth
					variant="outlined"
					defaultValue={history.MerchHistory}
					onKeyUp={(e) => (updatedHistory["MerchHistory"] = e.target.value)}
				></TextField>
				<h4>Communications</h4>
				<TextField
					autoComplete="new-password"
					multiline
					rows={6}
					fullWidth
					variant="outlined"
					defaultValue={history.CommsHistory}
					onKeyUp={(e) => (updatedHistory["CommsHistory"] = e.target.value)}
				></TextField>
			</>
		);
	};

	//updates provider
	const updateProvider = (stayOpen) => {
		if (props.provider != null && updatedProvider != null) {
			const ID = props.provider.ID;
			const Parameter = [];
			const Value = [];

			for (var key in updatedProvider) {
				Parameter.push(key);
				Value.push(updatedProvider[key]);
			}

			updatedProvider = [];

			Auth.currentAuthenticatedUser().then((response) => {
				const requestParams = {
					headers: {
						Authorization: response.signInUserSession.idToken.jwtToken,
					},
					body: {
						User: response.signInUserSession.idToken.payload[
							"cognito:username"
						],
						Groups:
							response.signInUserSession.idToken.payload["cognito:groups"],
						ID,
						Parameter,
						Value,
					},
				};
				API.patch("providers", "/provider-table/provider", requestParams)
					.then((response) => {
						if (
							response.statusCode === 200 &&
							response.body !== "Nothing was updated"
						) {
							props.setProvider(response.body, stayOpen);
						} else if (response.statusCode === 403) {
							setNoAuthorization(true);
						}
					})
					.catch((error) => {
						setNoAuthentication(true);
					});
			});
		}
	};

	//closes entryview and saves changes
	const close = () => {
		const Parameter = [];
		const Value = [];

		if (updatedHistory != null) {
			for (var key in updatedHistory) {
				Parameter.push(key);
				Value.push(updatedHistory[key]);
			}
			if (Parameter.length !== 0) {
				// Only calls updateHistory() if changes have been made

				Auth.currentAuthenticatedUser().then((response) => {
					const requestParams = {
						headers: {
							Authorization: response.signInUserSession.idToken.jwtToken,
						},
						body: {
							Groups:
								response.signInUserSession.idToken.payload["cognito:groups"],
							Parameter,
							Value,
						},
					};
					API.patch(
						"providers",
						"/history-table/" + props.provider.ID,
						requestParams
					)
						.then((response) => {
							if (response.statusCode === 403) {
								setNoAuthorization(true);
							}
						})
						.catch((error) => {
							setNoAuthentication(true);
						});
				});
			}
			updatedHistory = [];
		}
		if (props.provider.Group !== checkedGroups) {
			updatedProvider["Group"] = checkedGroups;
			updateProvider(false);
		}

		if (showHistory) {
			setShowHistory(!showHistory);
		}

		props.onClose();
	};

	//stages changes made in editProvider
	const saveEdit = (
		title,
		fName,
		lName,
		pNum,
		email,
		org,
		pos,
		address,
		suburb,
		state,
		postcode,
		phone,
		fax
	) => {
		if (title !== props.provider.Title) {
			updatedProvider["Title"] = title;
		}
		if (fName !== props.provider.FirstName) {
			updatedProvider["FirstName"] = fName;
		}
		if (lName !== props.provider.LastName) {
			updatedProvider["LastName"] = lName;
		}
		if (pNum !== props.provider.ProviderNumber) {
			updatedProvider["ProviderNumber"] = pNum;
		}
		if (email !== props.provider.Email) {
			updatedProvider["Email"] = email;
		}
		if (org !== props.provider.Practice) {
			updatedProvider["Practice"] = org;
		}
		if (pos !== props.provider.Position) {
			updatedProvider["Position"] = pos;
		}
		if (address !== props.provider.Street) {
			updatedProvider["Street"] = address;
		}
		if (suburb !== props.provider.Suburb) {
			updatedProvider["Suburb"] = suburb;
		}
		if (state !== props.provider.State) {
			updatedProvider["State"] = state;
		}
		if (postcode !== props.provider.PostCode) {
			updatedProvider["PostCode"] = postcode;
		}
		if (phone !== props.provider.Phone) {
			updatedProvider["Phone"] = phone;
		}
		if (fax !== props.provider.Fax) {
			updatedProvider["Fax"] = fax;
		}
		updateProvider(true);
		setEditOpen(false);
	};

	//deletes a provider based on provider.ID
	const deleteProvider = () => {
		Auth.currentAuthenticatedUser().then((response) => {
			var Groups = [];
			if (
				response.signInUserSession.idToken.payload["cognito:groups"] !==
				undefined
			) {
				Groups = response.signInUserSession.idToken.payload["cognito:groups"];
			}

			const requestParams = {
				headers: {
					Authorization: response.signInUserSession.idToken.jwtToken,
				},
				body: {
					Groups: Groups,
				},
			};
			API.del(
				"providers",
				"/provider-table/" + props.provider.ID,
				requestParams
			)
				.then((response) => {
					if (response.statusCode === 200) {
						props.deleteProvider(props.provider);
					} else if (response.statusCode === 403) {
						setNoAuthorization(true);
					}
				})
				.catch((error) => {
					setNoAuthentication(true);
				});
		});
	};
	return (
		<>
			<ThemeProvider theme={styles.themeDefault}>
				<Dialog open={props.open} onClose={() => close()} maxWidth="xl">
					<DialogContent>
						<table id="entryViewTable">
							<tbody>
								<tr>
									{getInfo()}
									<td rowSpan={3} id="textFields">
										{showHistory ? getHistory() : getReports()}
									</td>
								</tr>
								<tr>
									<td id="groups">
										<div
											style={{
												maxHeight: 150,
												overflowY: "auto",
												marginTop: 8,
												marginBottom: 8,
											}}
										>
											{props.groups.map((value) => {
												return (
													<ListItem
														key={value.name}
														dense
														button
														onClick={() => {
															handleGroupCheck(value.name);
														}}
													>
														<ListItemIcon>
															<Checkbox
																edge="start"
																label={value.name}
																checked={checkGroup(value)}
															></Checkbox>
														</ListItemIcon>
														<ListItemText primary={value.name} />
													</ListItem>
												);
											})}
										</div>
									</td>
								</tr>
								<tr>
									<td id="buttons">
										<Grid container spacing={1}>
											<Grid item>
												<Button
													variant="outlined"
													color="primary"
													onClick={() => setShowHistory(!showHistory)}
													style={{ width: 270 }}
												>
													{showHistory ? "Show Reports" : "Show Record History"}
												</Button>
											</Grid>
										</Grid>
										<Grid container spacing={1}>
											<Grid item>
												{checkActive() ? (
													<Button
														disabled={!userIsReadWrite}
														variant="outlined"
														color="primary"
														style={{ width: 107 }}
														onClick={() => setEditOpen(true)}
													>
														Edit
													</Button>
												) : (
													<Button
														disabled={!userIsAdmin}
														variant="outlined"
														style={
															userIsAdmin
																? {
																		width: 107,
																		borderColor: "#E73E33",
																		color: "#E73E33",
																  }
																: { width: 107 }
														}
														onClick={() => setDeleteClient(true)}
													>
														Delete
													</Button>
												)}
											</Grid>
											<Grid item>
												<Button
													variant="outlined"
													color="primary"
													style={{ width: 154 }}
													onClick={() => setConfirmInactive(true)}
												>
													{checkActive() ? "Make Inactive" : "Make Active"}
												</Button>
											</Grid>
										</Grid>
									</td>
								</tr>
							</tbody>
						</table>
					</DialogContent>
				</Dialog>
				<Dialog open={deleteClient} onClose={() => setDeleteClient(false)}>
					<DialogTitle>Delete Client</DialogTitle>
					<DialogContent>
						Are you sure you want to delete provider{" "}
						{props.provider != null
							? props.provider.Title +
							  " " +
							  props.provider.FirstName +
							  " " +
							  props.provider.LastName
							: " "}
						?
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setDeleteClient(false)}>Cancel</Button>
						<Button
							variant="contained"
							style={{ color: "white", backgroundColor: "#E73E33" }}
							onClick={() => {
								deleteProvider();
								setDeleteClient(false);
							}}
						>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={confirmInactive}
					onClose={() => setConfirmInactive(false)}
				>
					<DialogTitle>
						Make {checkActive() ? "Inactive" : "Active"}
					</DialogTitle>
					<DialogContent>
						Are you sure you want to make this record{" "}
						{checkActive() ? "inactive" : "active"}?
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setConfirmInactive(false)}>Cancel</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								if (props.provider.Active) {
									updatedProvider["Active"] = false;
									updateProvider(true);
								} else {
									updatedProvider["Active"] = true;
									updateProvider(true);
								}

								// this.updateEntryViewUI();
								setConfirmInactive(false);
							}}
						>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
				<EditProvider
					open={editOpen}
					onClose={() => setEditOpen(false)}
					provider={props.provider}
					saveEdit={(
						title,
						fName,
						lName,
						pNum,
						email,
						org,
						pos,
						address,
						suburb,
						state,
						postcode,
						phone,
						fax
					) =>
						saveEdit(
							title,
							fName,
							lName,
							pNum,
							email,
							org,
							pos,
							address,
							suburb,
							state,
							postcode,
							phone,
							fax
						)
					}
				/>
			</ThemeProvider>
			<NoAuthentication
				logout={props.logout}
				open={noAuthentication}
				onClose={() => setNoAuthentication(false)}
			/>
			<NoAuthorization
				open={noAuthorization}
				onClose={() => setNoAuthorization(false)}
			/>
		</>
	);
}
