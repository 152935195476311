import React from "react";
import ReactDOM from "react-dom";
import Login from "./login";
import "bootstrap/dist/css/bootstrap.min.css";
import { Amplify } from "aws-amplify";
import config from "./Utilities/config";
import { BrowserRouter as Router } from "react-router-dom";

//Setup Amplify
Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID,
	},
	API: {
		endpoints: [
			{
				name: "providers",
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
			},
		],
	},
});

//Render the application
ReactDOM.render(
	<Router>
		<Login />
	</Router>,
	document.getElementById("root")
);
