import React from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Snackbar,
	IconButton,
} from "@material-ui/core";
import { ForgotPassword } from "./forgotPassword";
import { Close } from "@material-ui/icons";
import { Auth } from "aws-amplify";
import Validate from "../../Utilities/validate";

//Written by Harrison Norris
export const ResetPassword = React.memo(
	(props) => {
		const [forgotPassword, setForgotPassword] = React.useState(false);
		const [errors, setErrors] = React.useState({
			old: false,
			new: false,
			confirm: false,
		});
		const [passwordResetSnackbar, setSnackbar] = React.useState(false);

		//Validate fields
		const validate = () => {
			var newErrors = errors;
			if (
				document.getElementById("resetPasswordNew").value !==
				document.getElementById("resetPasswordConfirm").value
			) {
				newErrors.confirm = true;
			} else {
				newErrors.confirm = false;
			}
			if (
				!Validate.password(document.getElementById("resetPasswordNew").value)
			) {
				newErrors.new = true;
			} else {
				newErrors.new = false;
			}
			//reset the error array to have no errors
			setErrors({
				old: newErrors.old,
				new: newErrors.new,
				confirm: newErrors.confirm,
			});
			if (newErrors.new || newErrors.confirm) {
				return false;
			} else {
				return true;
			}
		};

		//Reset password with Amplify
		const reset = () => {
			Auth.currentAuthenticatedUser()
				.then((user) => {
					return Auth.changePassword(
						user,
						document.getElementById("resetPasswordOld").value,
						document.getElementById("resetPasswordNew").value
					);
				})
				.then((data) => {
					setSnackbar(true);
					close();
				})
				.catch(() => {
					var newErrors = errors;
					newErrors.old = true;
					setErrors(newErrors);
				});
		};

		//Reset state
		const close = () => {
			props.onClose(false);
			setErrors({
				old: false,
				new: false,
				confirm: false,
			});
		};

		return (
			<>
				<ForgotPassword
					open={forgotPassword}
					onClose={() => setForgotPassword(false)}
				/>
				<Dialog open={props.open} onClose={() => close()}>
					<DialogTitle>Reset Password</DialogTitle>
					<DialogContent>
						<div style={{ width: 200 }}>
							<TextField
								fullWidth
								type="password"
								label="Old password"
								id="resetPasswordOld"
								error={errors.old}
								helperText={errors.old ? "Wrong password" : null}
							/>
							<br />
							<TextField
								fullWidth
								type="password"
								label="New password"
								id="resetPasswordNew"
								error={errors.new}
								helperText={
									errors.new
										? "Password must be at least 8 characters and contain a mix of uppercase and lowecase letters, numbers and symbols"
										: null
								}
							/>
							<br />
							<TextField
								fullWidth
								type="password"
								label="Confirm new password"
								id="resetPasswordConfirm"
								error={errors.confirm}
								helperText={errors.confirm ? "Passwords don't match" : null}
							/>
							<br />
							<div style={{ textAlign: "center", marginTop: 8 }}>
								<button
									style={{ border: 0, backgroundColor: "transparent" }}
									onClick={() => setForgotPassword(true)}
								>
									Forgot Password?
								</button>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => close()}>Cancel</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								if (validate()) {
									reset();
								}
							}}
						>
							Reset
						</Button>
					</DialogActions>
				</Dialog>
				<Snackbar
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					open={passwordResetSnackbar}
					autoHideDuration={2000}
					onClose={() => setSnackbar(false)}
					message="Password has been reset"
					action={
						<div>
							<IconButton
								size="small"
								color="inherit"
								onClick={() => setSnackbar(false)}
							>
								<Close></Close>
							</IconButton>
						</div>
					}
				/>
			</>
		);
	},
	(prev, next) => {
		if (prev.open !== next.open) {
			return false;
		} else {
			return true;
		}
	}
);
