import React from "react";
import "./database.css";
import { API, Auth } from "aws-amplify";
import Toolbar from "./toolbar";
import EntryView from "./Dialogs/entryView";
import Table from "./table";
import "react-virtualized/styles.css";
import { NoAuthentication } from "../notAuthenticated";
import { NoAuthorization } from "../notAuthorized";

//Written by Harrison Norris and Sarah Swyer
export default function Database(props) {
	const [noAuthentication, setNoAuthentication] = React.useState(false);
	const [noAuthorization, setNoAuthorization] = React.useState(false);
	const [sortUp, setSortUp] = React.useState(true);
	const [sortBy, setSortBy] = React.useState([
		false,
		false,
		true,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
		false,
	]);
	const [entryViewProps, setEntryViewProps] = React.useState({
		open: false,
		provider: null,
	});
	React.useEffect(() => {
		sort();
	}, [sortBy, sortUp]);
	React.useEffect(() => {
		sort();
	}, [props.providers.length]);

	//searches providers based on search boxes
	const searchProviders = () => {
		var searchedProviders = [...props.providers];
		for (var i in searchedProviders) {
			if (
				safeToUpperCase(searchedProviders[i].FirstName).indexOf(
					document.getElementById("fName").value.toUpperCase()
				) !== -1 &&
				safeToUpperCase(searchedProviders[i].LastName).indexOf(
					document.getElementById("lName").value.toUpperCase()
				) !== -1 &&
				safeToUpperCase(searchedProviders[i].ProviderNumber).indexOf(
					document.getElementById("providerNum").value.toUpperCase()
				) !== -1 &&
				safeToUpperCase(searchedProviders[i].Practice).indexOf(
					document.getElementById("practice").value.toUpperCase()
				) !== -1 &&
				safeToUpperCase(searchedProviders[i].Email).indexOf(
					document.getElementById("email").value.toUpperCase()
				) !== -1 &&
				safeToUpperCase(searchedProviders[i].Street).indexOf(
					document.getElementById("address").value.toUpperCase()
				) !== -1 &&
				safeToUpperCase(searchedProviders[i].Suburb).indexOf(
					document.getElementById("suburb").value.toUpperCase()
				) !== -1 &&
				safeToUpperCase(searchedProviders[i].State).indexOf(
					document.getElementById("state").value.toUpperCase()
				) !== -1 &&
				safeToUpperCase(searchedProviders[i].PostCode)
					.toString()
					.indexOf(document.getElementById("postcode").value.toUpperCase()) !==
					-1 &&
				safeToUpperCase(searchedProviders[i].Phone).indexOf(
					document.getElementById("phone").value.toUpperCase()
				) !== -1 &&
				safeToUpperCase(searchedProviders[i].Fax).indexOf(
					document.getElementById("fax").value.toUpperCase()
				) !== -1
			) {
				searchedProviders[i].show = true;
			} else {
				searchedProviders[i].show = false;
				searchedProviders[i].selected = false;
			}
		}
		props.setProviders(searchedProviders);
	};

	//Safe uppercase with no errors for null or undefined
	const safeToUpperCase = (str) => {
		var upperCase;
		try {
			upperCase = str.toUpperCase();
			return upperCase;
		} catch (e) {
			if (str === undefined) {
				return " ";
			}
			if (str === null) {
				return " ";
			} else {
				return " ";
			}
		}
	};

	//toggles a filter group
	const toggleFilter = (i) => {
		var tempGroups = [...props.groups];
		tempGroups[i].active = !tempGroups[i].active;
		props.setGroups(tempGroups);
	};

	//Checks filters when groups state changes
	React.useEffect(() => {
		var tempProviders = [...props.providers];
		for (var i in tempProviders) {
			var filter = true;
			for (var j = 0; j < props.groups.length; j++) {
				if (props.groups[j].active) {
					if (!tempProviders[i].Group.includes(props.groups[j].name)) {
						filter = false;
					}
				}
			}
			if (filter) {
				tempProviders[i].filtered = true;
			} else {
				tempProviders[i].filtered = false;
				tempProviders[i].selected = false;
			}
		}
		props.setProviders(tempProviders);
	}, [props.groups]);

	//sorts providers based on sort paramaters
	const sort = () => {
		var tempProviders = [...props.providers];
		tempProviders = tempProviders.sort(function (a, b) {
			if (sortBy[0]) {
				if (a.Title === "") {
					return false;
				}
				if (!sortUp) {
					return a.Title.localeCompare(b.Title);
				} else {
					return b.Title.localeCompare(a.Title);
				}
			}
			if (sortBy[1]) {
				if (a.FirstName === "") {
					return false;
				}
				if (!sortUp) {
					return a.FirstName.localeCompare(b.FirstName);
				} else {
					return b.FirstName.localeCompare(a.FirstName);
				}
			}
			if (sortBy[2]) {
				if (a.LastName === "") {
					return false;
				}
				if (!sortUp) {
					return a.LastName.localeCompare(b.LastName);
				} else {
					return b.LastName.localeCompare(a.LastName);
				}
			}
			if (sortBy[3]) {
				if (a.ProviderNumber === "") {
					return false;
				}
				if (!sortUp) {
					return a.ProviderNumber.localeCompare(b.ProviderNumber);
				} else {
					return b.ProviderNumber.localeCompare(a.ProviderNumber);
				}
			}
			if (sortBy[4]) {
				if (a.Practice === "") {
					return false;
				}
				if (!sortUp) {
					return a.Practice.localeCompare(b.Practice);
				} else {
					return b.Practice.localeCompare(a.Practice);
				}
			}
			if (sortBy[5]) {
				if (a.Street === "") {
					return false;
				}
				if (!sortUp) {
					return a.Street.localeCompare(b.Street);
				} else {
					return b.Street.localeCompare(a.Street);
				}
			}
			if (sortBy[6]) {
				if (a.Suburb === "") {
					return false;
				}
				if (!sortUp) {
					return a.Suburb.localeCompare(b.Suburb);
				} else {
					return b.Suburb.localeCompare(a.Suburb);
				}
			}
			if (sortBy[7]) {
				if (a.State === "") {
					return false;
				}
				if (!sortUp) {
					return a.State.localeCompare(b.State);
				} else {
					return b.State.localeCompare(a.State);
				}
			}
			if (sortBy[8]) {
				if (a.PostCode === "") {
					return false;
				}
				if (!sortUp) {
					return a.PostCode.localeCompare(b.PostCode);
				} else {
					return b.PostCode.localeCompare(a.PostCode);
				}
			}
			if (sortBy[9]) {
				if (a.Phone === "") {
					return false;
				}
				if (!sortUp) {
					return a.Phone.localeCompare(b.Phone);
				} else {
					return b.Phone.localeCompare(a.Phone);
				}
			}
			if (sortBy[10]) {
				if (a.Fax === "") {
					return false;
				}
				if (!sortUp) {
					return a.Fax.localeCompare(b.Fax);
				} else {
					return b.Fax.localeCompare(a.Fax);
				}
			}
			if (sortBy[11]) {
				if (a.Email === "") {
					return false;
				}
				if (!sortUp) {
					return a.Email.localeCompare(b.Email);
				} else {
					return b.Email.localeCompare(a.Email);
				}
			}
			return b.LastName.localeCompare(a.LastName);
		});
		var sortedProviders = tempProviders.sort(function (a, b) {
			if (sortBy[0]) {
				if (a.Title === "") {
					return false;
				}
				if (sortUp) {
					return a.Title.localeCompare(b.Title);
				} else {
					return b.Title.localeCompare(a.Title);
				}
			}
			if (sortBy[1]) {
				if (a.FirstName === "") {
					return false;
				}
				if (sortUp) {
					return a.FirstName.localeCompare(b.FirstName);
				} else {
					return b.FirstName.localeCompare(a.FirstName);
				}
			}
			if (sortBy[2]) {
				if (a.LastName === "") {
					return false;
				}
				if (sortUp) {
					return a.LastName.localeCompare(b.LastName);
				} else {
					return b.LastName.localeCompare(a.LastName);
				}
			}
			if (sortBy[3]) {
				if (a.ProviderNumber === "") {
					return false;
				}
				if (sortUp) {
					return a.ProviderNumber.localeCompare(b.ProviderNumber);
				} else {
					return b.ProviderNumber.localeCompare(a.ProviderNumber);
				}
			}
			if (sortBy[4]) {
				if (a.Practice === "") {
					return false;
				}
				if (sortUp) {
					return a.Practice.localeCompare(b.Practice);
				} else {
					return b.Practice.localeCompare(a.Practice);
				}
			}
			if (sortBy[5]) {
				if (a.Street === "") {
					return false;
				}
				if (sortUp) {
					return a.Street.localeCompare(b.Street);
				} else {
					return b.Street.localeCompare(a.Street);
				}
			}
			if (sortBy[6]) {
				if (a.Suburb === "") {
					return false;
				}
				if (sortUp) {
					return a.Suburb.localeCompare(b.Suburb);
				} else {
					return b.Suburb.localeCompare(a.Suburb);
				}
			}
			if (sortBy[7]) {
				if (a.State === "") {
					return false;
				}
				if (sortUp) {
					return a.State.localeCompare(b.State);
				} else {
					return b.State.localeCompare(a.State);
				}
			}
			if (sortBy[8]) {
				if (a.PostCode === "") {
					return false;
				}
				if (sortUp) {
					return a.PostCode.localeCompare(b.PostCode);
				} else {
					return b.PostCode.localeCompare(a.PostCode);
				}
			}
			if (sortBy[9]) {
				if (a.Phone === "") {
					return false;
				}
				if (sortUp) {
					return a.Phone.localeCompare(b.Phone);
				} else {
					return b.Phone.localeCompare(a.Phone);
				}
			}
			if (sortBy[10]) {
				if (a.Fax === "") {
					return false;
				}
				if (sortUp) {
					return a.Fax.localeCompare(b.Fax);
				} else {
					return b.Fax.localeCompare(a.Fax);
				}
			}
			if (sortBy[11]) {
				if (a.Email === "") {
					return false;
				}
				if (sortUp) {
					return a.Email.localeCompare(b.Email);
				} else {
					return b.Email.localeCompare(a.Email);
				}
			}
			return b.LastName.localeCompare(a.LastName);
		});
		props.setProviders(sortedProviders);
	};

	//opens entryview
	const openEntryView = (provider) => {
		setEntryViewProps({ provider: provider, open: true });
	};

	//Updates provider table based on an edited provider from entryview
	const setProvider = (provider, stayOpen) => {
		var tempProviders = [...props.providers];
		for (var i in tempProviders) {
			if (provider.ID === tempProviders[i].ID) {
				tempProviders[i] = provider;
				tempProviders[i].selected = false;
				tempProviders[i].show = true;
				tempProviders[i].filtered = true;
			}
		}

		setEntryViewProps({ provider: provider, open: stayOpen });
		props.setProviders(tempProviders);
	};

	//deletes a provider
	const deleteProvider = (provider) => {
		var tempProviders = [...props.providers];
		for (var i in tempProviders) {
			if (provider.ID === tempProviders[i].ID) {
				tempProviders.splice(i, 1);
			}
		}
		setEntryViewProps({ provider: null, open: false });
		props.setProviders(tempProviders);
	};

	return (
		<>
			<EntryView
				open={entryViewProps.open}
				onClose={() => setEntryViewProps({ provider: null, open: false })}
				provider={entryViewProps.provider}
				groups={props.groups}
				setProvider={(provider, stayOpen) => setProvider(provider, stayOpen)}
				deleteProvider={(provider) => deleteProvider(provider)}
				logout={props.logout}
			/>
			<Toolbar
				readProviders={props.readProviders}
				providers={props.providers}
				groups={props.groups}
				selected={[1]}
				searchProviders={() => searchProviders()}
				toggleFilter={(i) => toggleFilter(i)}
				logout={props.logout}
				setProviders={props.setProviders}
				setProvider={(provider, stayOpen) => setProvider(provider, stayOpen)}
				setGroups={props.setGroups}
			/>
			<Table
				openEntryView={(provider) => openEntryView(provider)}
				sortUp={sortUp}
				setSortUp={setSortUp}
				sortBy={sortBy}
				setSortBy={setSortBy}
				providers={props.providers}
				showInactive={props.showInactive}
				setProviders={(providers) => props.setProviders(providers)}
			/>
			<NoAuthentication
				logout={props.logout}
				open={noAuthentication}
				onClose={() => setNoAuthentication(false)}
			/>
			<NoAuthorization
				open={noAuthorization}
				onClose={() => setNoAuthorization(false)}
			/>
		</>
	);
}
